import React from "react";
import { useHistory } from "react-router-dom";
import { Col } from "reactstrap";

const OnLargeDevices = ({ data }) => {
    const history = useHistory();
    return (
        <>
            {data.map((section, index) => (
                <Col key={index} className="mb-md-0 mb-md-3 mb-2">
                    <p className="mb-5 fs--22">{section.title}</p>
                    {section.items.map((item, i) => (
                        <p
                            role="button"
                            onClick={() => history.push(item.link)}
                            key={i}
                            className="mb-4 fs--18 footer-nav"
                        >
                            {item.name}
                        </p>
                    ))}
                </Col>
            ))}{" "}
        </>
    );
};

export default OnLargeDevices;
