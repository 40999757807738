import React from "react";
import ContactusHero from "../../components/contactus/ContactusHero";
import FormSection from "../../components/contactus/FormSection";

const ContactUs = () => {
    return (
        <>
            <ContactusHero />
            <FormSection />
        </>
    );
};

export default ContactUs;
