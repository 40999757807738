import React from "react";
import { Col, Container, Row } from "reactstrap";
import ConatctImg from "../../assets/img/contactus.png";

const ContactusHero = () => {
    return (
        <Container className="bg-site-light-sky py-5 mt-5" fluid>
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="d-flex flex-column justify-content-between h-100  at-anz-section ">
                            <p>
                                ANZ Billing is dedicated to providing you and
                                your patients comprehensive and innovative
                                billing services tailored for your practice.
                            </p>
                            <p className=" pb-4">
                                Everything from improving your staff efficiency,
                                to strengthening doctor patient coordinations,
                                we’ve got your medical billing covered from A to
                                Z.
                            </p>
                            <h5 className=" pb-4 ">
                                Fill out the form below if you’re interested in
                                <br></br>
                                working with us.
                            </h5>
                        </div>
                    </Col>
                    <Col className="d-lg-block d-none">
                        <div>
                            <img alt="" src={ConatctImg}></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default ContactusHero;
