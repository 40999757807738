import React from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import InfoButton from "../services/InfoButton";

const GetStarted = () => {
    const history = useHistory();
    return (
        <Container className="py-5 bg-site-light-blue rounded">
            <Row className="my-5">
                <Col>
                    <div className="d-flex text-center">
                        <div className="w-50  d-md-flex d-none align-items-end justify-content-end">
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="74"
                                height="63"
                                viewBox="0 0 174 163"
                                fill="none"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M70.1538 11.6704C63.8783 10.1999 57.6831 8.6901 51.368 7.40327C50.0104 7.12148 48.693 7.99 48.4173 9.33966C48.1355 10.6973 49.0118 12.021 50.3615 12.2967C56.6406 13.5812 62.7837 15.0765 69.0153 16.5386C70.3626 16.8503 71.7062 16.0141 72.0198 14.6807C72.3315 13.3334 71.5011 11.9821 70.1538 11.6704Z"
                                    fill="black"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M85.5335 62.7096C67.5243 72.2218 50.007 82.6381 32.2125 92.5281C31.0046 93.2036 30.5734 94.724 31.241 95.9258C31.9166 97.1337 33.4307 97.5728 34.6465 96.9033C52.4069 87.025 69.8898 76.6202 87.8667 67.1338C89.0924 66.4844 89.5538 64.9745 88.9124 63.7549C88.263 62.5292 86.7531 62.0682 85.5335 62.7096Z"
                                    fill="black"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M140.853 110.993C132.705 126.161 124.969 141.581 116.652 156.657C115.993 157.865 116.432 159.383 117.634 160.05C118.844 160.722 120.368 160.275 121.035 159.073C129.356 143.975 137.104 128.539 145.256 113.349C145.907 112.135 145.454 110.619 144.24 109.969C143.017 109.312 141.509 109.771 140.853 110.993Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                        <div className="w-75 mx-auto ">
                            <h4>
                                Take the first step towards improving your
                                healthcare practice's financial health. Sign up
                                now to access our top-tier medical billing
                                services
                            </h4>

                            <InfoButton
                                title={"Get Started"}
                                onClick={() =>
                                    history.push("/about/contact-us")
                                }
                            />
                            {/* <Button
                                onClick={() =>
                                    history.push("/about/contact-us")
                                }
                                className="bg-site-dark-blue  text-white rounded-pill px-5 fw--500 mt-3 "
                            >
                                Get Started
                            </Button> */}
                        </div>
                        <div className="w-50 d-flex justify-content-start d-md-block d-none">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="98"
                                height="80"
                                viewBox="0 0 98 137"
                                fill="none"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.4905 120.689C22.5425 125.148 25.5225 129.586 28.7028 133.954C29.3836 134.896 30.6901 135.103 31.624 134.424C32.5662 133.743 32.7724 132.428 32.0931 131.495C28.9338 127.149 25.9777 122.749 22.9482 118.32C22.2907 117.365 20.9863 117.123 20.0382 117.771C19.0833 118.428 18.833 119.734 19.4905 120.689Z"
                                    fill="black"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M38.8374 80.4054C55.3619 84.7037 72.0933 88.1642 88.6986 92.1072C89.8282 92.3723 90.9549 91.6753 91.2214 90.554C91.4864 89.4244 90.7977 88.2964 89.6667 88.0231C73.0891 84.0921 56.3855 80.6439 39.8955 76.3479C38.7698 76.0566 37.6303 76.7301 37.3375 77.8476C37.0462 78.9734 37.7199 80.1126 38.8374 80.4054Z"
                                    fill="black"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M31.9094 19.2397C45.5298 14.461 59.0375 9.29373 72.7109 4.66732C73.8018 4.29195 74.3885 3.10459 74.0214 2.01222C73.6528 0.911611 72.4572 0.326358 71.3648 0.693475C57.6764 5.33111 44.1521 10.5014 30.5168 15.2913C29.4274 15.675 28.8502 16.8691 29.2338 17.9585C29.6189 19.0562 30.8118 19.6248 31.9094 19.2397Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default GetStarted;
