import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { Collapse, Container } from "reactstrap";

function FAQ() {
    const { search } = useLocation();

    const [showGlossary, setShowGlossary] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(
        Array(arr.length).fill(false)
    );

    const toggle = (index) => {
        const newOpenCollapse = openCollapse.map((value, i) =>
            i === index ? !value : false
        );
        setOpenCollapse(newOpenCollapse);
    };

    useEffect(() => {
        if (search) {
            const element = document.getElementById("faq-container");
            element.scrollIntoView({
                behavior: "instant",
                block: "end",
                inline: "nearest",
            });
        }
    }, [search]);
    return (
        <Container id="faq-container" className="py-5 resourse">
            {/* <h4 className="my-2">FAQ</h4> */}

            <div>
                {" "}
                {arr.map((content, index) => (
                    <>
                        <div
                            key={`pm-${index}`}
                            onClick={() => toggle(index)}
                            className="cursor-pointer"
                        >
                            <img src={content.icon} alt="" />
                            <h5 className=" text-site-dark-blue fs--16 fw--500">
                                {content.heading}{" "}
                                <i
                                    className={`fas ms-1 fa-chevron-${
                                        openCollapse[index] ? "up" : "down"
                                    } text-dark`}
                                ></i>
                            </h5>
                        </div>
                        <Collapse isOpen={openCollapse[index]}>
                            <p className="mt-3">{content.content}</p>
                        </Collapse>
                    </>
                ))}
            </div>

            <div
                className="mt-5"
                onClick={() => setShowGlossary(!showGlossary)}
            >
                <h3>
                    Glossary{" "}
                    <span className="cursor-pointer">
                        {showGlossary ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </span>
                </h3>
            </div>
            {showGlossary && (
                <div className="mt-4">
                    {healthcareTerms.map((item, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                            <p>
                                <span className="text-site-dark-blue">
                                    {item.term}
                                </span>
                                : {item.definition}{" "}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </Container>
    );
}

export default FAQ;

const arr = [
    {
        heading: "What is medical billing?",
        content:
            "Medical billing is the process of submitting and following up on claims with health insurance companies to receive payment for medical services provided by a healthcare provider.",
    },
    {
        heading: "Why is medical billing important?",
        content:
            "Medical billing ensures that healthcare providers receive timely and accurate reimbursement for the services they provide. It plays a vital role in the financial health of medical practices by managing claims, reducing billing errors, and maximizing revenue.",
    },
    {
        heading: "What services does your medical billing company provide?",
        content:
            "Our medical billing company offers a comprehensive range of services including Revenue Cycle Management (RCM), practice management, medical billing and coding, patient experience management, credentialing, and now, web design and development.",
    },
    {
        heading: "How can your medical billing services benefit my practice?",
        content:
            "Our services are designed to streamline billing processes, minimize claim denials, and maximize revenue for your practice. By leveraging our expertise, you can reduce administrative burdens, improve cash flow, and enhance overall practice efficiency.",
    },
    {
        heading:
            "How do you ensure patient information security and HIPAA compliance?",
        content:
            "We adhere to strict HIPAA regulations and employ robust security measures to safeguard patient information. Our team undergoes regular training to stay updated on compliance requirements, and we utilize advanced technology to maintain data confidentiality.",
    },
    {
        heading:
            "How long does it take to start seeing results with your medical billing services?",
        content:
            "The timeline for seeing results can vary depending on factors such as practice size, complexity of billing processes, and current revenue cycle performance. However, our goal is to implement efficient strategies that yield noticeable improvements in revenue and claims processing within a few billing cycles.",
    },
    {
        heading: "How much does your medical billing service cost?",
        content:
            "Our pricing is tailored to the specific needs of each practice and is based on factors such as practice size, specialty, and volume of claims. We offer transparent pricing structures and customizable packages to ensure affordability and value for our clients.",
    },
    {
        heading: "What sets your medical billing company apart from others?",
        content:
            "Our medical billing company distinguishes itself through our personalized approach, unwavering dedication to client satisfaction, and commitment to excellence. We prioritize open communication, reliability, and availability, ensuring that each client receives the attention and support they deserve. Additionally, our focus on accuracy, transparency, and security sets us apart as a trusted partner in medical billing solutions.",
    },
];

const healthcareTerms = [
    {
        term: "Authorization",
        definition:
            "The process of obtaining approval from an insurance company or other entity before providing medical services.",
    },
    {
        term: "Claims",
        definition:
            "A request for payment submitted by a healthcare provider to an insurance company or government program.",
    },
    {
        term: "Claim Errors",
        definition:
            "Mistakes made in submitting medical claims, such as incorrect coding or missing information.",
    },
    {
        term: "Coding",
        definition:
            "The process of translating medical diagnoses and procedures into codes for billing purposes.",
    },
    {
        term: "Compliance",
        definition:
            "The act of adhering to the legal and ethical standards of the healthcare industry.",
    },
    {
        term: "Credentialing",
        definition:
            "The process of verifying a healthcare provider's education, training, and licensure.",
    },
    {
        term: "Customer Service",
        definition:
            "The support provided to patients and healthcare providers to ensure a positive experience.",
    },
    {
        term: "Deductible",
        definition:
            "The amount a patient must pay out of pocket before their insurance coverage begins.",
    },
    {
        term: "Denials",
        definition:
            "When an insurance company refuses to pay for a claim submitted by a healthcare provider.",
    },
    {
        term: "Electronic Health Records (EHR)",
        definition:
            "Digital records of a patient's medical history and treatment, used for billing and other purposes.",
    },
    {
        term: "Enrollment",
        definition:
            "The process of signing up for health insurance or government programs.",
    },
    {
        term: "Fee Schedule",
        definition:
            "A list of charges for medical services provided by a healthcare provider.",
    },
    {
        term: "HIPAA",
        definition:
            "The Health Insurance Portability and Accountability Act, a federal law that protects patient privacy and regulates healthcare providers' use of medical information.",
    },
    {
        term: "ICD-10",
        definition:
            "The International Classification of Diseases, 10th Revision, used to code medical diagnoses and procedures.",
    },
    {
        term: "Insurance Verification",
        definition:
            "The process of confirming a patient's insurance coverage and benefits.",
    },
    {
        term: "Medical Billing",
        definition:
            "The process of submitting claims for reimbursement to insurance companies or government programs.",
    },
    {
        term: "Medical Claims Review",
        definition:
            "The process of reviewing claims submitted by healthcare providers to ensure they meet billing and medical standards.",
    },
    {
        term: "Medical Coding",
        definition:
            "The process of assigning codes to medical diagnoses and procedures for billing purposes.",
    },
    {
        term: "Medicare",
        definition:
            "A federal health insurance program for people over 65 and those with certain disabilities or conditions.",
    },
    {
        term: "Payer Follow-up",
        definition:
            "The process of communicating with insurance companies to ensure timely payment of claims.",
    },
    {
        term: "Payment Posting",
        definition:
            "The process of recording payments received from insurance companies or patients.",
    },
    {
        term: "Patient Satisfaction",
        definition:
            "The level of satisfaction a patient experiences with their healthcare provider's services.",
    },
    {
        term: "Practice Management",
        definition:
            "The administrative tasks involved in running a healthcare practice, such as scheduling appointments and managing finances.",
    },
    {
        term: "Prior Authorization",
        definition:
            "The process of obtaining approval from an insurance company before providing certain medical services.",
    },
    {
        term: "Release of Information",
        definition:
            "The process of sharing a patient's medical information with authorized individuals or entities.",
    },
    {
        term: "Revenue Cycle Management",
        definition:
            "The process of managing a healthcare provider's revenue, from patient registration to payment posting.",
    },
];
