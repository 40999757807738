import React from "react";
import { Col, Container, Row } from "reactstrap";
import hiring from "../../assets/img/hiring.svg";
import medicine from "../../assets/img/medicine.svg";
import printing_invoices from "../../assets/img/printing_invoices.svg";
import revenue from "../../assets/img/revenue.svg";
import support from "../../assets/img/support.svg";

import CheckMark from "./Icons/CheckMark";
import Heart from "./Icons/Heart";
import MoneyBar from "./Icons/MoneyBar";
import PaperDollar from "./Icons/PaperDollar";
import PaperPen from "./Icons/PaperPen";
import Screen from "./Icons/Screen";

const OurServices = () => {
    return (
        <Container>
            {sections.map((content) => (
                <Row className="services">
                    <Col>
                        {content.icon}
                        <h1 className="fs--26 fs-md--38">{content.heading}</h1>
                        <p className="fs--20 py-3">{content.para}</p>
                        <div className="d-flex align-items-center gap-3"></div>
                    </Col>
                    <Col className="d-none d-lg-flex ">
                        <img
                            src={content.image}
                            alt=""
                            className="img-fluid w-75 service-image"
                        />
                    </Col>
                </Row>
            ))}
        </Container>
    );
};

export default OurServices;

const sections = [
    {
        heading: "Streamline Your Financial Operations with RCM.",
        para: "Comprehensive revenue cycle management solution that allows you to shift the burden of collections off your shoulders, so you can focus on patient care.",
        image: revenue,
        icon: <MoneyBar />,
    },
    {
        heading: "Optimize Your Workflow",
        para: "Get a customized practice management solution that works for your unique needs We understand that every medical practice is different. That's why we offer customized practice management solutions that are tailored to your unique needs.",
        image: medicine,
        icon: <PaperPen />,
    },
    {
        heading:
            "Simplify Your Billing and Coding Processes for Maximum Revenue",
        para: "Accurate medical coding is essential for ensuring that your practice receives the maximum reimbursement for the services you provide. Our team of certified coders uses the latest coding guidelines to ensure that your claims are submitted correctly and promptly.",
        image: printing_invoices,
        icon: <PaperDollar />,
    },
    {
        heading: "Put Your Patients First",
        para: "Say goodbye to complex medical billing procedures and hello to a better patient experience with our Patient Experience Management service. Trust us to handle the billing details so you can focus on providing excellent care.",
        image: support,
        icon: <Heart />,
    },
    {
        heading: "Comprehensive Credentialing",
        para: "At ANZ, we understand that the process of credentialing can be time-consuming and confusing. That's why we offer comprehensive credentialing services to help you focus on what you do best - providing excellent patient care.",
        image: hiring,
        icon: <CheckMark />,
    },
    {
        heading: "Fast Track Treatment Approval",
        para: "At ANZ, we understand that the process of credentialing can be time-consuming and confusing. That's why we offer comprehensive credentialing services to help you focus on what you do best - providing excellent patient care.",
        image: hiring,
        icon: <Screen />,
    },
];
