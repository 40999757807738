import React from "react";
import FAQ from "../components/resourses/FAQ";

const Resources = () => {
    return (
        <div>
            <h1 className="text-center my-3">FAQ</h1>
            {/* <Articles />
            <Testimonials />
            <Calendar /> */}
            <FAQ />
        </div>
    );
};

export default Resources;
