import React from "react";
import { useLocation } from "react-router-dom";

const DropMenu = ({ menuText, children, isOpen, toggleMenu }) => {
    const { pathname } = useLocation();
    const isActive = pathname.includes(menuText.toLowerCase());
    return (
        <div className="position-relative">
            <div
                className="d-flex align-items-center gap-2"
                role="button"
                onClick={toggleMenu}
            >
                <h5 className={isOpen || isActive ? "active-nav" : ""}>
                    {menuText}{" "}
                </h5>
                <i
                    className={`fas fa-chevron-${isOpen ? "up" : "down"} ${
                        isOpen || isActive ? "active-nav" : ""
                    } fs--22`}
                ></i>
            </div>
            <div className={isOpen ? "" : "d-none"}>
                <div className="menu-tip"></div>
                <div className="menu-items">{children}</div>
            </div>
        </div>
    );
};

export default DropMenu;
