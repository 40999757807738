import { useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/footer/Footer";
import SideBar from "../components/sidebar/SideBar";

const Main = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSideBar = () => setIsOpen(!isOpen);

    return (
        <>
            <div className="d-block d-md-none">
                <SideBar isOpen={isOpen} toggleSideBar={toggleSideBar} />
            </div>
            <div>
                <Header toggleSideBar={toggleSideBar} />
            </div>
            {props.children}
            <Footer />
        </>
    );
};

export default Main;
