import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { servicesImages } from "../../assets/img/Services";
import InfoButton from "../../components/services/InfoButton";
import InfoText from "../../components/services/InfoText";
import ServiceHeroSection from "../../components/services/ServiceHeroSection";

const PatientExperience = () => {
    const history = useHistory();

    return (
        <div>
            <ServiceHeroSection
                title="Patient Experience Management"
                text="Say goodbye to complex medical billing procedures and hello to a better patient experience with our Patient Experience Management service. Trust us to handle the billing details so you can focus on providing excellent care."
                image={servicesImages.PatientExperiment}
            />
            <Container className="d-flex flex-column justify-content-center align-items-center my-5">
                <h5 className="text-center max-w-850 fs--16 fs-sm--22 px-1 fw--500 my-5">
                    At ANZ Billing, we understand the importance of a seamless
                    patient experience. Our Patient Experience Management
                    services ensure your patients receive top-notch care from
                    start to finish.
                </h5>
                <Row className="justify-content-between">
                    {contentData.map((data) => (
                        <Col
                            sm={12}
                            lg={5}
                            className="d-flex flex-column  justify-content-center align-items-center my-5 lg-box"
                        >
                            <img
                                src={data.icon}
                                alt=""
                                className="pe-res-img"
                            />
                            <p className="fs--16 fw--600 my-4">{data.title}</p>
                            <p className="fs--16 fw--40 text-md-center">
                                {data.text}
                            </p>
                        </Col>
                    ))}
                </Row>
            </Container>

            <Container fluid className="">
                <Container className="bg-site-secondary rounded py-5 mt-5 ">
                    {/* <InfoSection> */}
                    <Row className="align-items-center ">
                        <Col sm={12} lg={6} className=" ps-md-5 pe-md-4">
                            <h3 className="fw--600">
                                Top priority: safeguarding patient privacy. We
                                follow strict security standards to ensure
                                confidentiality.
                            </h3>
                            <InfoText className={"d-none d-lg-block"}>
                                Click 'Get Started' to see our commitment to
                                patient privacy, with strict security measures
                                and industry standards.
                            </InfoText>
                            <InfoButton
                                onClick={() =>
                                    history.push("/about/contact-us")
                                }
                                className={"d-none d-lg-block"}
                                title={"Learn more"}
                            />
                        </Col>
                        <Col
                            sm={10}
                            lg={6}
                            className="d-flex justify-content-end "
                        >
                            <img
                                src={servicesImages.CredentialingPeople}
                                className="pe-md-5 img-fluid w-50"
                                alt=""
                            />
                        </Col>
                        {/* <InfoButton className={"d-lg-none"} title={"Learn more"} /> */}
                    </Row>
                    {/* </InfoSection> */}
                </Container>
            </Container>

            {/* <TestimonialPFP
                img={servicesImages.TestimonialPFP}
                name={"Mannan Razzak"}
                title={"MD, FAAP"}
                description={
                    "Salma and her team are transparent with their work,  diligent in submitting claims correctly the first time and following up on denied claims and also accessible and respectful of our staff and patient families. I highly recommend ANZ Billing for your practices’ medical billing needs."
                }
            /> */}
        </div>
    );
};

export default PatientExperience;

export const contentData = [
    {
        title: "Clear Communication",
        text: "Good communication is key to a positive patient experience. That’s why we keep patients informed every step of the way, from insurance verification to payment processing.",
        icon: servicesImages.ClearCommunication,
    },
    {
        title: "Personalized Service",
        text: "Every patient is unique, and ANZ Billing's Patient Experience Management services treat them as such. Our team works closely with patients to understand their needs and deliver tailored service.",
        icon: servicesImages.PersonalizeService,
    },
    {
        title: "Efficient Billing",
        text: "You and your patients appreciate quick, accurate billing, and ANZ Billing's Patient Experience Management services ensure that billing is handled promptly and without error.",
        icon: servicesImages.EfficientBilling,
    },
    {
        title: "Improved Reputation",
        text: "A positive patient experience is crucial to building a strong reputation in the healthcare industry. Our dedication to delivering excellent patient experience helps ensure that your patients are satisfied and eager to recommend your practice to others.",
        icon: servicesImages.ImprovedReputation,
    },
];
