import React from "react";
import {
    FaAppStore,
    FaGlobeAsia,
    FaRegBuilding,
    FaShoppingCart,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { servicesImages } from "../../assets/img/Services";
import CustomerReviews from "../../components/services/CustomerReviews";
import ServiceHeroSection from "../../components/services/ServiceHeroSection";
import GetStartedBtn from "../../components/common/GetStartedBtn";

const WebsiteDevelopment = () => {
    const history = useHistory();
    return (
        <React.Fragment>
            <ServiceHeroSection
                title="Web Design & Development"
                text="Web Design & Development Client-focused, customer-centric, creating website solutions that deliver tangible business results, Appnovation's web developers helps brands the ever-changing digital landscape."
                image={servicesImages.WebDev2}
            />

            <Container className=" my-5 content-section">
                <h1 className="text-center fw--600 my-5 ">
                    What Web Solution Do You Need?
                </h1>
                <p className="text-center fs--24">
                    Web development services help create all types of web-based
                    software and ensure great experience for web users.
                    Different types of web solutions may seem similar from the
                    outside but we approach them differently and know what
                    factors are winning in each case.
                </p>
            </Container>

            <Container className="mx-auto">
                <Row className="justify-content-between mx-2">
                    {dataContent.map((content, index) => (
                        <Col
                            key={`pm-${index}`}
                            md="12"
                            lg="5"
                            className="pt-5 p-md-5  my-5   text-center border shadow-sm rounded "
                        >
                            <div className="">
                                {/* <img src={content.icon} alt="" /> */}
                                {content.icon}
                                <h5 className="my-4 fs--22 fw--500 text-title-text">
                                    {content.title}
                                </h5>
                                <p className="text-title-text">
                                    {content.text}
                                </p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <CustomerReviews />
            <Container className="mt-3 mb-5">
                <div className="d-flex justify-content-center align-items-center">
                    <GetStartedBtn />
                </div>
            </Container>
        </React.Fragment>
    );
};

export default WebsiteDevelopment;
const dataContent = [
    {
        title: "Web portals",
        text: "ANZ Billing works with web portals for different audiences: customers, business partners, ecommerce users, patients, vendors, interest-based communities. Web portals we create automatically aggregate data from corporate systems and become a source of up-to-date information and help for users.",
        icon: <FaGlobeAsia size={40} className="text-icon-color" />, // Adjusted the size here
    },
    {
        title: "Websites",
        text: "250+ businesses, governmental and non-profit organizations use the websites we’ve created for corporate presentation and brand building. We make sure our websites have an easy-to-use page editor for dynamic content management.",
        icon: <FaRegBuilding size={40} className="text-icon-color" />, // And here
    },
    {
        title: "Ecommerce",
        text: "20 years in ecommerce development, we’ve grown the expertise from entry-level shops for startups to custom ecommerce solutions built for large-scale and high-growth businesses. We multiply business efficiency by using scalable microservices architectures and enabling high automation of all business processes.",
        icon: <FaShoppingCart size={40} className="text-icon-color" />, // And here
    },
    {
        title: "Web apps",
        text: "In our portfolio of 1,900+ created web apps, you will find solutions for efficient management of different business activities. We apply smart automation to streamline workflows and integrate corporate apps together for coherent operation.",
        icon: <FaAppStore size={40} className="text-icon-color" />, // And here
    },
];
