import { useState, useEffect } from 'react';

function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial call to set screenWidth to the correct value
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return screenWidth;
}

export default useScreenWidth;
