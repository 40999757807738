import React from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "reactstrap";
import avatar1 from "../../assets/img/Ellipse 18.png";
import avatar2 from "../../assets/img/Ellipse 33.png";
import avatar3 from "../../assets/img/Ellipse 34.png";
import TestimonialCard from "../cards/TestimonialCard";

const TestimonialsSection = () => {
    const arr = [
        {
            img: avatar1,
            name: "Dr. Mannan Razzak, MD, FAAP",
            description:
                "  “Salma and her team are transparent with their work,  diligent in submitting claims correctly the first time and following up on denied claims and also accessible and respectful of...",
        },
        {
            img: avatar2,
            name: "Dr. Ray Santos, MD",
            description:
                "  We have worked with Salma and her team for over 5 years and it is like working with your family.  They take pride in their work and also make sure that your reputation is cared for as well...",
        },
        {
            img: avatar3,
            name: "Dr. Rosemarie A Osowik",
            description:
                "  [ANZ Billing] helped us successfully transition from hospital employed to private practice! Their expertise allowed us to achieve a highest collection rate I have ever had! ",
        },
    ];
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 3000,
        arrows: false,
    };
    return (
        <Container className="bg-site-secondary px-md-5 py-5 my-5 rounded">
            <Container>
                <Row className="mt-2 mb-5">
                    <div className="d-flex justify-content-center  align-items-center">
                        <h1 className="text-site-primary mb-0 fw--600">
                            Testimonials
                        </h1>
                    </div>
                </Row>
                <Row className="my-3 d-md-flex d-none">
                    {arr.map((object, index) => {
                        return (
                            <Col
                                key={`testimonial-${index}`}
                                md={12}
                                lg={4}
                                className="mt-md-0 my-3"
                            >
                                <TestimonialCard {...object} />
                            </Col>
                        );
                    })}
                </Row>
                <Row className="my-3 d-md-none d-flex  home-slider">
                    <Slider {...settings}>
                        {arr.map((obj, index) => {
                            return (
                                <div
                                    key={index}
                                    className="p-3 h-100 d-flex justify-content-center  "
                                >
                                    <TestimonialCard
                                        imageAlign={"center"}
                                        {...obj}
                                    />
                                </div>
                            );
                        })}
                    </Slider>
                </Row>
            </Container>
        </Container>
    );
};

export default TestimonialsSection;
