import React from "react";
import { Col, Container, Row } from "reactstrap";
import { servicesImages } from "../../assets/img/Services";
import GetStartedBtn from "../../components/common/GetStartedBtn";
import ServiceHeroSection from "../../components/services/ServiceHeroSection";
import SiteCard from "../../components/services/SiteCard";

const Credentialing = () => {
    return (
        <div>
            <ServiceHeroSection
                smScreenCol={["col-6", "col-6"]}
                title="Credentialing"
                text={
                    "At ANZ, we understand that the process of credentialing can be time-consuming and confusing. That's why we offer comprehensive credentialing services to help you focus on what you do best - providing excellent patient care."
                }
                image={servicesImages.Credentialing}
            />
            <Container className="my-5 py-5">
                <Row className="bg-site-lite justify-content-around rounded-30 pb-4 mx-1  pt-5">
                    {dataContent.map((content) => (
                        <Col sm={12} lg={3}>
                            <SiteCard
                                img={content.img}
                                title={content.title}
                                text={content.text}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container>
                {dataContent2.map((content) => (
                    <Row className="services">
                        <Col>
                            {content.icon}
                            <h1 className="fs-42">{content.title}</h1>
                            <p className="fs--22 py-3">{content.text}</p>
                            <div className="d-flex align-items-center gap-3"></div>
                        </Col>
                        <Col className="d-none d-lg-flex ">
                            <img
                                src={content.img}
                                alt=""
                                className="img-fluid w-75 service-image"
                            />
                        </Col>
                    </Row>
                ))}
            </Container>

            <Container fluid className="">
                <Container className="text-center p-3 p-lg-5 bg-site-secondary rounded">
                    <div className="px-lg-5">
                        <h1 className="fs--24 fs-md-28 fw--600  px-0 px-lg-5">
                            Let us take care of the paperwork and administrative
                            tasks so you can focus on providing the best care
                            possible to your patients.{" "}
                        </h1>
                        <h1 className="fs--24 fs-md-28  fs--500 my-5">
                            Contact us today to learn more about our
                            credentialing services.
                        </h1>
                        <div className="d-flex justify-content-center w-100"> 
                            <GetStartedBtn />
                        </div>
                    </div>
                </Container>
            </Container>
            {/* <TestimonialPFP
                img={servicesImages.TestimonialPFP}
                name={"Name"}
                title={"Title"}
                description={
                    "We have blind faith that ANZ is handling our accounts well. We have worked with a large billing company before and they would make several sloppy mistakes costing us thousands of dollars and their support staff was horrendous.  Working with ANZ is like having another partner in your business"
                }
            /> */}
        </div>
    );
};

export default Credentialing;

export const dataContent = [
    {
        img: servicesImages.Compliance,
        title: "Compliance",
        text: "We work with you to ensure compliance with insurance companies, government agencies, and industry standards.",
    },
    {
        img: servicesImages.TimeSaving,
        title: "Time-saving",
        text: "Let us save you time and allow you to focus on providing quality care to patients.",
    },
    {
        img: servicesImages.Accuracy,
        title: "Accuracy",
        text: "We help you reduce the risk of errors and improve the provider's chances of being approved for reimbursement.",
    },
];

export const dataContent2 = [
    {
        img: servicesImages.Unstepping,
        title: "Stay ahead of the competition",
        text: "Our Contracting and Networking Services help you identify and establish relationships with key healthcare providers and insurance carriers, giving you a competitive advantage in the marketplace, and allowing you to unlock new opportunities for growth.",
    },
    {
        img: servicesImages.ReviewedDoc,
        title: "Keep your practice up to compliance.",
        text: "At ANZ, we uphold the highest ethical standards and comply with all federal and state regulations, including HIPAA, to protect your patients' sensitive information. Our team receives ongoing training and education to stay up-to-date on industry standards, and we conduct regular internal audits to ensure full compliance. We also provide ongoing maintenance and support to keep our clients in compliance with evolving regulations.",
    },
];

export const dataContent3 = [
    {
        img: {
            imgLg: servicesImages.Laptop,
            imgSm: servicesImages.Covid,
        },
        title: {
            titleLg:
                "Major Credit Bureaus Remove Medical Debt Collections Under $500",
            titleSm: "Article Title",
        },
        description: {
            descriptionLg:
                "Equifax, Experian, and TransUnion have announced they wiped medical debt collections under $500 from US credit reports to help consumers data. they wiped medical debt collections under $500 from US credit reports to help consumers data",
            descriptionSm: "info",
        },
        metaData: "Jacqueline LaPointe . April 12, 2023",
        readMore: true,
        xs: 12,
        lg: 6,
    },
    {
        img: {
            imgLg: servicesImages.CMS,
            imgSm: servicesImages.Golves,
        },
        title: {
            titleLg:
                "CMS Resumes IDR Payment Determinations Under No Surprises Act",
            titleSm: "Article Title",
        },
        description: {
            descriptionLg: "Jacqueline LaPointe",
            descriptionSm: "Info",
        },
        xs: 6,
        lg: 3,
    },
    {
        img: {
            imgLg: servicesImages.CMS,
            imgSm: servicesImages.DoctorMobile,
        },
        title: {
            titleLg:
                "CPT® Evaluation and Management (E/M) Code and Guideline Changes",
            titleSm: "Article Title",
        },
        description: {
            descriptionLg: "",
            descriptionSm: "info",
        },
        xs: 6,
        lg: 3,
    },
];
