import React from "react";
import MailImg from "../../assets/img/mail_send.png";
const SuccessfullySubmited = ({ applyJob }) => {
    return (
        <div>
            <h1 className="mb-5 ">We got your message!</h1>
            {applyJob ? (
                <div>
                    <p className="fw--500">
                        Thank you for your interest in ANZ Billing! We have
                        received your application and will be reviewing it
                        carefully.
                    </p>
                </div>
            ) : (
                <div>
                    <p className="fw--500">
                        Thank you for submitting our contact form.
                    </p>
                    <p className="fw--500">
                        We appreciate your interest in working with us. Rest
                        assured, our team will respond within 24 hours to
                        address your inquiry.
                    </p>
                    <p className="fw--500">
                        We look forward to the opportunity to collaborate with
                        you and discuss how we can meet your needs. If you
                        require immediate assistance or have any urgent
                        questions, please don't hesitate to reach out to us
                        directly.
                    </p>
                </div>
            )}

            <div className="text-center mt-5">
                <img src={MailImg} alt=""></img>
            </div>
        </div>
    );
};

export default SuccessfullySubmited;
