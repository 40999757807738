import React from "react";

const SiteCard = ({ img, title, text }) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center px-4 py-3">
            {" "}
            <img src={img} alt="" className="credentialingImg" />
            <h5 className="my-3 fw--60">{title}</h5>
            <p className="text-center">{text}</p>
        </div>
    );
};

export default SiteCard;
