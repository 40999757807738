import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import ContactForm from "../contactus/ContactForm";
import SuccessfullySubmited from "../contactus/SuccessfullySubmited";

function ContactUsModal({ modal, toggle }) {
    const [isSubmitted, setIsSubmited] = useState(false);

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                onClosed={() => setIsSubmited(false)}
            >
                <ModalHeader toggle={toggle}>Get in Touch!</ModalHeader>
                {isSubmitted ? (
                    <div
                        className="px-4 shadow py-5"
                        style={{ borderRadius: 20 }}
                    >
                        <SuccessfullySubmited />
                    </div>
                ) : (
                    <div
                        className="px-4 shadow py-5"
                        style={{ borderRadius: 20 }}
                    >
                        <ContactForm setIsSubmited={setIsSubmited} />
                    </div>
                )}{" "}
            </Modal>
        </div>
    );
}

export default ContactUsModal;
