import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { aboutRoutes, servicesRoutes } from "../Header/Header";
import BackButton from "../home/Icons/BackButton";
import CollapseMenu from "./CollapseMenu";
import SideBarDivider from "./SideBarDivider";

const SideBar = ({ isOpen, toggleSideBar }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const isActiveNav = (path) => (pathname === path ? "active-nav" : "");

    return (
        <div>
            <Offcanvas isOpen={isOpen}>
                <OffcanvasHeader>
                    <div className="d-flex">
                        <BackButton onClick={toggleSideBar} />
                        <h5 className="fw--700 fs--20 mt-2 w-100 text-center">
                            ANZ Billing
                        </h5>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <p
                        className={`fw--600 fs--18 cursor-pointer ${isActiveNav(
                            "/"
                        )}`}
                        onClick={() => history.push("/")}
                    >
                        Home
                    </p>
                    <SideBarDivider />
                    <CollapseMenu
                        title={"Our services"}
                        tabName="services"
                        routes={servicesRoutes}
                        toggleSideBar={toggleSideBar}
                    />{" "}
                    <SideBarDivider />
                    <CollapseMenu
                        title={"About us"}
                        tabName="about"
                        routes={aboutRoutes}
                        toggleSideBar={toggleSideBar}
                    />
                    <SideBarDivider />
                    <p
                        className={`fw--600 fs--18 cursor-pointer ${"about/contact-us"}`}
                        onClick={() => history.push("about/contact-us")}
                    >
                        Contact us
                    </p>
                    <SideBarDivider />
                    <p
                        className={`fw--600 fs--18 cursor-pointer ${isActiveNav(
                            "/resources"
                        )}`}
                        onClick={() => history.push("/resources")}
                    >
                        Resources and FAQ
                    </p>
                    <SideBarDivider />
                </OffcanvasBody>
            </Offcanvas>
        </div>
    );
};

export default SideBar;
