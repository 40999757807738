import React from "react";

const BackButton = ({ onClick }) => {
    return (
        <div role="button" onClick={onClick}>
            <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#166EE4" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.2934 8.65975C18.6795 9.03956 18.6733 9.66388 18.2799 10.036L13.2712 14.7734C12.8541 15.1679 12.8541 15.8319 13.2712 16.2264L18.2799 20.964C18.6733 21.3361 18.6795 21.9604 18.2934 22.3402C17.9213 22.7063 17.3243 22.7063 16.9522 22.3402L10.7245 16.2127C10.3263 15.8209 10.3263 15.1789 10.7245 14.7871L16.9522 8.65977C17.3243 8.29366 17.9213 8.29365 18.2934 8.65975Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default BackButton;
