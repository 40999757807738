import React from "react";
import OurMissionHero from "../../components/ourmission/OurMissionHero";
import TalentedStaff from "../../components/ourmission/TalentedStaff";
import GetStarted from "../../components/ourmission/GetStarted";
import ImagesSwiper from "../../components/about/ImagesSwiper";
import OurHistory from "../../components/ourmission/OurHistory";

const OurMission = () => {
    return (
        <>
            <OurMissionHero />
            <OurHistory />
            <TalentedStaff />
            <GetStarted />
            {/* <ImagesSwiper /> */}
        </>
    );
};

export default OurMission;
