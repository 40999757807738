import React, { useState } from "react";
import { Input, Progress } from "reactstrap";

const FileUpload = ({ uploadedUrl, isLoading }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState("");

    const cloudName = "dshonutxt";
    const uploadPreset = "lamnqupl";

    const handleUpload = async (selectedFile) => {
        setError("");
        if (!selectedFile) return;

        const validTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        const maxSize = 2 * 1024 * 1024; // 2 MB

        if (!validTypes.includes(selectedFile.type)) {
            setError(
                "Invalid file type. Only PDF and DOC/DOCX files are allowed."
            );
            return;
        }

        if (selectedFile.size > maxSize) {
            setError("File size exceeds the 2 MB limit.");
            return;
        }

        isLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("upload_preset", uploadPreset);

        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(progress);
        };

        xhr.open(
            "POST",
            `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
            true
        );

        xhr.onload = () => {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                // console.log("Upload complete: ", response);
                uploadedUrl(response.secure_url);
                isLoading(false);
                // console.log("Uploaded URL: ", response.secure_url);
            } else {
                isLoading(false);
                console.error("Upload failed with status: ", xhr.status);
            }
        };

        xhr.onerror = (error) => {
            isLoading(false);
            console.error("Upload failed: ", error);
        };

        xhr.send(formData);
    };

    return (
        <div>
            <Input
                type="file"
                name="attachment"
                accept=".pdf, .docx"
                required
                onChange={(event) => handleUpload(event.target.files[0])}
            />
            {error && <small style={{ color: "red" }}>{error}</small>}
            {uploadProgress > 0 && uploadProgress < 100 && (
                <Progress animated value={uploadProgress} className="mt-2" />
            )}
        </div>
    );
};

export default FileUpload;
