import React from "react";
import { Col, Container, Row } from "reactstrap";
import useScreenWidth from "../../hooks/useScreenWidth";

const ServiceHeroSection = ({
    smScreenCol,
    title,
    text,
    textSmScreen,
    image,
}) => {
    const screenWidth = useScreenWidth();
    return (
        <>
            <Container fluid className="py-5 hero-background">
                <Container>
                    <Row className="  py-5">
                        <Col
                            sm={12}
                            lg={6}
                            className="d-flex flex-column align-items-start justify-content-center"
                        >
                            <h1 className="d-none d-lg-block fw--600">
                                {title}
                            </h1>
                            <p className="d-lg-none fs--32 fw--600">{title}</p>

                            <h5 className="fw--600 my-4 line-height text-white">
                                {(screenWidth < 426 && textSmScreen) || text}
                            </h5>
                        </Col>
                        <Col
                            sm={12}
                            lg={6}
                            className="d-none d-lg-flex justify-content-end service-hero-img"
                        >
                            <img src={image} className="img-fluid" alt="" />
                        </Col>
                        <Col className="d-lg-none">
                            <Row>
                                <Col
                                    className={
                                        (smScreenCol && smScreenCol[0]) ||
                                        "col-5"
                                    }
                                >
                                    {" "}
                                </Col>
                                <Col
                                    className={
                                        (smScreenCol && smScreenCol[1]) ||
                                        "col-7"
                                    }
                                >
                                    <img
                                        src={image}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <div className="arc"></div> */}
        </>
    );
};

export default ServiceHeroSection;
