import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";

const CollapseMenu = ({ routes, tabName, title, toggleSideBar }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const isActive = pathname.includes(tabName);
    const isActiveNav = (path) => (pathname === path ? "active-nav" : "");
    const [isOpen, setIsOpen] = useState(isActive);

    const handleClick = (path) => {
        history.push(path);
        toggleSideBar();
    };

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <div>
            <p
                onClick={toggleMenu}
                className={`fw--600 fs--18 cursor-pointer ${
                    isActive || isOpen ? "active-nav" : ""
                }`}
            >
                {title}
                <span>
                    <i
                        className={`fas fa-chevron-${isOpen ? "up" : "down"} ${
                            isOpen || isActive ? "active-nav" : ""
                        } fs--18 ms-3`}
                    ></i>
                </span>
            </p>

            <Collapse isOpen={isOpen} className="ms-3">
                {routes.map((route) => (
                    <p
                        role="button"
                        key={route.path}
                        className={`fs--14 fw--500 my-4 ${isActiveNav(
                            route.path
                        )}`}
                        onClick={() => handleClick(route.path)}
                    >
                        {" "}
                        {route.title}
                    </p>
                ))}
            </Collapse>
        </div>
    );
};

export default CollapseMenu;
