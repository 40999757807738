import Verification from "./verification.svg";
import ArFollowUp from "./a-r-follow-up.svg";
import CheckInCircle from "./check-in-circle.svg";
import ClaimError from "./claim-error.svg";
import ClaimSubmission from "./claim-submission.svg";
import DenialAppeal from "./denial-appeal.svg";
import EnterChanges from "./enter-changes.svg";
import MaleFemaleDoctor from "./male-female-doctor.svg";
import PaymentPosting from "./payment-posting.svg";
import RevenueGraph from "./revenue-graph.svg";
import RevenueGraphBg from "./graphInBg.svg";
import CredsEnrollment from "./creds-enrollement.svg";
import EFT from "./eft.svg";
import CustomerServices from "./customer-service.svg";
import FeesSchedule from "./fees-schedule.svg";
import PriorAuthorization from "./prior-authroization.svg";
import PatientSatisfaction from "./patient-satisfaction.svg";
import MedicalBilling from "./medical-billing.svg";
import PatientExperiment from "./patient-experiment.svg";
import Credentialing from "./credentialing.svg";
import CredentialingPeople from "./credentialing-illustration.svg";
import SecurityIllustration from "./security-illustration.svg";
import GirlWithHeart from "./girl-with-heart.svg";
import Telephone from "./telephone.svg";
import EfficientBilling from "./efficient-billing.svg";
import ImprovedReputation from "./improved-reputation.svg";
import PersonalizeService from "./personalize-service.svg";
import ClearCommunication from "./clear-comunication.svg";
import GraphCircle from "./graph-circle.svg";
import TestimonialPFP from "./testimonial-pfp.png";
import TimeSaving from "./timce-saving-icon.svg";
import Accuracy from "./accuracy-icon.svg";
import Compliance from "./compliance-icon.svg";
import ReviewedDoc from "./reviewed-doc.svg";
import Unstepping from "./unstepping.svg";
import CMS from "./cms.png";
import Laptop from "./laptop.png";
import DoctorMobile from "./doctor-mobile.png";
import Golves from "./golves.png";
import Covid from "./covid-19.png";
import WebDev from "./webdev.svg";
import WebDev2 from "./webdev2.svg";
import WebLogo from './world-wide-web.png'

export const servicesImages = {
    Verification,
    ArFollowUp,
    CheckInCircle,
    ClaimError,
    ClaimSubmission,
    DenialAppeal,
    EnterChanges,
    MaleFemaleDoctor,
    PaymentPosting,
    RevenueGraph,
    RevenueGraphBg,
    CredsEnrollment,
    EFT,
    CustomerServices,
    FeesSchedule,
    PriorAuthorization,
    PatientSatisfaction,
    MedicalBilling,
    PatientExperiment,
    Credentialing,
    CredentialingPeople,
    GirlWithHeart,
    Telephone,
    EfficientBilling,
    ImprovedReputation,
    PersonalizeService,
    ClearCommunication,
    GraphCircle,
    TestimonialPFP,
    TimeSaving,
    Compliance,
    Accuracy,
    ReviewedDoc,
    Unstepping,
    Laptop,
    CMS,
    DoctorMobile,
    Golves,
    Covid,
    SecurityIllustration,
    WebDev,
    WebDev2,
    WebLogo
};
