import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import FinancialImg from "../../assets/img/finanicalImg.svg";
import ContactUsModal from "../common/ContactUsModal";
import InfoButton from "../services/InfoButton";

const FinancialHealth = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <Container fluid className="pt-5" style={{ marginTop: "8rem" }}>
            <Container className=" px-5 d-flex flex column align-items-center justify-content-left position-relative finanical rounded">
                <Row className="w-100  ">
                    <img
                        src={FinancialImg}
                        alt=""
                        className="image-setting d-lg-block d-none"
                    ></img>
                    <Col lg={7} md={5} className="d-lg-block d-none"></Col>
                    <Col
                        lg={5}
                        md={12}
                        className="d-flex flex-column justify-content-left align-items-center px--md-2 px-0 "
                    >
                        <div className="text-left">
                            {" "}
                            <h3 className="mb-4 fw--600">
                                Take the first step towards improving your
                                healthcare practice's financial health.
                            </h3>
                            <p className="mb-4  fs--22 fw--500">
                                Your Free Consultation is just a click Away!
                            </p>
                            <InfoButton
                                onClick={toggle}
                                title={"Free Consultation"}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <ContactUsModal modal={modal} toggle={toggle} />
        </Container>
    );
};

export default FinancialHealth;
