import React, { useState } from "react";
import { Button, Container } from "reactstrap";

import { IoIosArrowDown } from "react-icons/io";
import talentedStaff from "../../assets/img/talentedStaff.jpg";
import ApplyJobModal from "../common/ApplyJobModal";
import InfoButton from "../services/InfoButton";

const TalentedStaff = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        // <Container className="talentedstaff-container" fluid>
        <>
            <Container className="my-5">
                <div className="d-flex w-100 mb-4 pt-5 justify-content-center  align-items-center">
                    <h1 className="text-site-primary mb-0 fw--600">
                        Meet Our Talented Staff
                    </h1>
                </div>
                {/* <h1 >Meet Our Talented Staff</h1> */}
                {/* <IoIosArrowDown className="fs--35" /> */}
                <div>
                    <img
                        src={talentedStaff}
                        alt="talented-team"
                        className="img-fluid w-100 rounded"
                    />
                </div>
                {/* <div className="d-sm-block d-none ">
                    <Row>
                        {arr.map(({ picture, name, designation }, index) => {
                            return (
                                <Col
                                    xl={2}
                                    lg={3}
                                    md={4}
                                    sm={12}
                                    className="mt-3"
                                >
                                    <Card className="rounded text-center">
                                        <div className="p-2">
                                            <img alt="" src={picture}></img>
                                        </div>
                                        <CardBody>
                                            <h5>{name}</h5>
                                            <h6>{designation}</h6>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </div> */}
                {/* <Row className="d-sm-none d-block">
                    <Col>
                        <StaffSlider arr={arr} />
                    </Col>
                </Row> */}
                <hr className="border-site-dark-blue my-5" />
                <div className=" our-mission">
                    <p className="w-50">
                        We are always looking for dedicated individuals who
                        share our commitment to excellence and want to join our
                        team.
                    </p>
                    <p className="w-50  mt-3">
                        Join us in making a positive impact on the healthcare
                        industry and the lives of patients.
                    </p>
                    <InfoButton
                        title={"Join our team"}
                        onClick={toggle}
                    ></InfoButton>
                </div>
            </Container>
            <ApplyJobModal modal={modal} toggle={toggle} />
        </>
        // </Container>
    );
};

export default TalentedStaff;
// const arr = [
//     {
//         picture: Pic1,
//         name: "Salma Khan",
//         designation: "CEO",
//     },
//     {
//         picture: Pic2,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic3,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic4,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic5,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic6,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic7,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic8,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic9,
//         name: "Name",
//         designation: "Role",
//     },
//     {
//         picture: Pic10,
//         name: "Name",
//         designation: "Role",
//     },
// ];
