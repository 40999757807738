import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { servicesImages } from "../../assets/img/Services";
import GetStartedBtn from "../../components/common/GetStartedBtn";
import CheckedText from "../../components/services/CheckedText";
import InfoButton from "../../components/services/InfoButton";
import InfoText from "../../components/services/InfoText";
import ServiceHeroSection from "../../components/services/ServiceHeroSection";

const RevenueCycleManagement = () => {
    const history = useHistory();
    return (
        <React.Fragment>
            <ServiceHeroSection
                title="Revenue Cycle Management"
                text="Comprehensive revenue cycle management solution that allows you to shift the burden of collections off your shoulders, so you can focus on patient care."
                image={servicesImages.RevenueGraph}
            />
            <Container className=" content-section">
                <h5 className="text-center fs--32 mb-5 pb-5 d-none d-md-block">
                    Let us Handle
                </h5>
                <Row className="justify-content-between ">
                    {cardsContent.map((content, index) => (
                        <Col
                            key={`rcm-${index}`}
                            sm="12"
                            lg="5"
                            className="d-flex flex-column flex-md-row align-items-center align-items-md-start  gap-3 gap-md-4 my-5"
                        >
                            <img src={content.icon} alt="" />
                            <div className=" text-center text-md-left">
                                <h5 className="fs--24 fw--600">
                                    {content.title}
                                </h5>
                                <p className="mt-4 fs--18 fw--500">
                                    {content.text}
                                </p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container className="bg-site-secondary rounded py-5 my-5">
                {/* <InfoSection> */}
                <Row className="align-items-center ">
                    <Col sm={12} lg={6} className="px-2 ps-md-5 pe-md-4">
                        <h3 className="fw--600">
                            Unlock the potential of your practice with tailored
                            practice management solutions.{" "}
                        </h3>
                        <InfoText className={"d-none d-lg-block"}>
                            We provide customized strategies designed
                            specifically for your unique needs. Maximize
                            efficiency, improve revenue cycles, and enhance
                            patient care.
                        </InfoText>
                        {/* <InfoButton
                            className={"d-none d-lg-block"}
                            title={"Learn more"}
                        /> */}
                    </Col>
                    <Col
                        sm={10}
                        lg={6}
                        className=" d-flex justify-content-end "
                    >
                        <img
                            src={servicesImages.MaleFemaleDoctor}
                            className="pe-md-5 img-fluid"
                            alt=""
                        />
                    </Col>
                    <div className="px-2">
                        <InfoButton
                            className={"d-lg-none "}
                            title={"Learn more"}
                        />
                    </div>
                </Row>
                {/* </InfoSection> */}
            </Container>
            <Container className="my-5 py-5">
                <Row className="justify-content-between">
                    <Col lg={5} className="p-md-0 m-md-0 my-3 ">
                        <p className="fw--600 fs--26 line-sm-height-2-5  line-sm-height-2">
                            Are you looking for a dedicated team to handle your
                            medical billing needs?
                        </p>
                        <p className="fs--18 mt-2">
                            Click "Get Started" to learn more about our
                            comprehensive suite of services and start optimizing
                            your revenue cycle today!
                        </p>
                        <GetStartedBtn />
                    </Col>
                    <Col lg={5} className="p-md-0 m-md-0">
                        <CheckedText>
                            90% of your claims are processed on the first
                            submission
                        </CheckedText>
                        <CheckedText>
                            Boost your collection rate to 97-98%
                        </CheckedText>
                    </Col>
                </Row>
            </Container>

            {/* <TestimonialPFP
                img={servicesImages.TestimonialPFP}
                name={"Mannan Razzak"}
                title={"MD, FAAP"}
                description={
                    "Well ANZ Billing of course has fulfilled all the above and more. We have been recommending ANZ Billing to many medical practices looking to switch billing companies and these practices have contracted with ANZ for their billing needs. As you know, referral is the high level of trust and recommendation we can make to another company. Salma and her team are transparent with their work,  diligent in submitting claims correctly the first time and following up on denied claims and also accessible and respectful of our staff and patient families. I highly recommend ANZ Billing for your practices’ medical billing needs."
                }
            /> */}
        </React.Fragment>
    );
};

export default RevenueCycleManagement;

const cardsContent = [
    {
        title: "Verification",
        text: "We verify patient insurance coverage and eligibility before treatment to avoid any surprises or issues with claims processing. Our team ensures that all necessary information is gathered to minimize claim rejections and delays.",
        icon: servicesImages.Verification,
    },
    {
        title: "Enter Charges",
        text: "Our experienced coding team captures and records all patient charges accurately and promptly to ensure timely and optimal reimbursement. We work closely with providers to ensure all charges are captured, maximizing revenue potential.",
        icon: servicesImages.EnterChanges,
    },
    {
        title: "Claim Submission",
        text: "We submit claims promptly and accurately to payers for maximum reimbursement. Our coders scrubs all claims for proper procedures and diagnosis to reduce claim denials and rejections.",
        icon: servicesImages.ClaimSubmission,
    },
    {
        title: "Claim Errors",
        text: "Our team thoroughly reviews all claims to minimize errors and reduce claim rejections. We catch errors early in the billing process to minimize delays and maximize revenue.",
        icon: servicesImages.ClaimError,
    },
    {
        title: "Payment Posting",
        text: "Proper posting is very important. All EOBs are revied carefully and posted promptly., Any patient responsibility is transferred to patient or if there is secondary insurance on file the claim will be forwarded to the secondary insurance.",
        icon: servicesImages.PaymentPosting,
    },
    {
        title: "A/R Follow-up",
        text: "Our dedicated team works diligently to follow-up on outstanding balances, including denials and appeals, to improve collection rates. We are proactive in our approach, working to identify any issues early on and finding resolution quickly.",
        icon: servicesImages.ArFollowUp,
    },
    {
        title: "Denials & Appeals",
        text: "We have a proven track record in effectively managing denials and appeals to ensure optimal reimbursement. Our team of experts is skilled at identifying the root cause of denials and appeals and working with payers to resolve them in a timely manner.",
        icon: servicesImages.DenialAppeal,
    },
    {
        title: "Patient Billing",
        text: "We provide clear and concise patient billing statements to minimize confusion and improve patient collections. Our team is dedicated to patient satisfaction and works to answer any questions or concerns promptly and professionally.",
        icon: servicesImages.PaymentPosting,
    },
];
