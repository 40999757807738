import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import Gyphyl from "../../assets/img/Gyphyl.svg";
import healthCare from "../../assets/img/HealthCare.svg";

const HeroSection = () => {
    return (
        <Container className="my-5 hero-section">
            <Card className=" bg-site-secondary site-border-radius site-secondary px-3 px-md-5 py-5">
                <Row className=" py-5 d-flex align-items-center p-sm-0">
                    <Col sm={12} lg={6} className="d-none d-lg-block">
                        <div className=" position-relative w-75 ">
                            <img
                                src={Gyphyl}
                                alt=""
                                className="img-fluid position-absolute"
                            />
                            <img
                                src={healthCare}
                                alt=""
                                className="img-fluid position-relative zindex-1"
                            />
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <h1 className="right-text fs--28 fs-sm--30">
                            Innovative medical billing solutions to financially
                            empower physicians
                        </h1>
                        <p className="para-text my-4">
                            With our comprehensive and efficient billing
                            services, you'll be able to focus on what you do
                            best - providing high-quality healthcare to your
                            patients. Count on us to provide you with
                            personalized attention and customized solutions that
                            meet your unique needs.
                        </p>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
};

export default HeroSection;
