import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import FileUpload from "./FileUpload";

const ContactForm = ({ setIsSubmited, applyJob }) => {
    const [submitting, setSubmitting] = useState(false);
    const [fileUploading, setFileUpload] = useState();
    const [fileUrl, setFileUrl] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!fileUrl && applyJob) {
            return;
        }

        setSubmitting(true);
        const formData = {};
        const formElements = event.target.elements;

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            if (element.tagName === "INPUT" || element.tagName === "TEXTAREA") {
                formData[element.name] = element.value;
            }
        }
        if (applyJob) {
            formData["file"] = fileUrl;
            delete formData["Practice or Company name"];
            delete formData["Occupation"];
            delete formData["attachment"];
        }

        let data = JSON.stringify(formData);
        let url = applyJob
            ? "https://script.google.com/macros/s/AKfycbwpQD438MLoois2gZDkWD_iVapD3dOlLBHwh1nKu15dyqLMvDXkzC9paccZ9Y2Wm4vAaQ/exec"
            : "https://script.google.com/macros/s/AKfycbySEcByIxVUCfMnvrZwXFe9D-qrqu8jID2QvxjxTsFxjqOzdBUeB0VQsU9M0lriqsmDNA/exec";

        let config = {
            redirect: "follow",
            method: "POST",
            headers: {
                "Content-Type": "text/plain;charset=utf-8",
            },
            url,
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                if (response?.data?.status === "OK") {
                    setSubmitting(false);
                    setIsSubmited(true);
                }
            })
            .catch((error) => {
                alert("something went wrong. try again!.");
                setSubmitting(false);
            });

        // Here you can perform further actions with the form data, such as sending it to an API or processing it in any way you need.

        // For example, if you want to reset the form after submission, you can uncomment the following line:
        // event.target.reset();
    };
    return (
        <Form onSubmit={handleSubmit}>
            {!applyJob && (
                <>
                    <FormGroup>
                        <Input
                            name="Occupation"
                            required
                            placeholder="I'm a..."
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            name="Practice or Company name"
                            required
                            placeholder="Practice or Company name"
                        />
                    </FormGroup>
                </>
            )}

            <Row className="mb-3">
                <Col>
                    {" "}
                    <Input
                        name="First Name"
                        required
                        placeholder="First Name"
                    />
                </Col>
                <Col>
                    {" "}
                    <Input name="Last Name" required placeholder="Last Name" />
                </Col>
            </Row>
            <FormGroup>
                <Input
                    required
                    id="email"
                    type="email"
                    name="Email"
                    placeholder="Email"
                />
            </FormGroup>
            <FormGroup>
                <Input required name="number" type="tel" placeholder="Number" />
            </FormGroup>

            {applyJob && (
                <FormGroup>
                    <FileUpload
                        uploadedUrl={setFileUrl}
                        isLoading={setFileUpload}
                    />
                </FormGroup>
            )}

            <FormGroup>
                <Input
                    required
                    id="message"
                    name="message"
                    type="textarea"
                    placeholder="Comments"
                    rows="5"
                    style={{ resize: "none" }}
                />
            </FormGroup>

            <Button
                disabled={submitting || fileUploading}
                type="submit"
                className="btn btn-primary  rounded-pill info-button info-border mx-auto  fs--20 fw--600 get-started "
            >
                {submitting ? "Submitting.." : "Submit"}
            </Button>
        </Form>
    );
};

export default ContactForm;
