import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from "reactstrap";

const OnSmallDevices = ({ data }) => {
    const history = useHistory();
    const [open, setOpen] = useState("");

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <div>
            <Accordion
                flush
                open={open}
                toggle={toggle}
                className="bg-site-primary"
            >
                {data?.map(({ title, items }, index) => {
                    return (
                        <AccordionItem
                            className="bg-site-primary "
                            key={`OnSmallOptions-${index}`}
                        >
                            <AccordionHeader
                                className="bg-site-primary"
                                targetId={`${index}`}
                            >
                                <p className="fs--20">{title}</p>
                            </AccordionHeader>
                            <AccordionBody
                                className="bg-site-primary"
                                accordionId={`${index}`}
                            >
                                {items.map((item, index) => {
                                    return (
                                        <p
                                            role="button"
                                            onClick={() =>
                                                history.push(item.link)
                                            }
                                            key={`item-${index}`}
                                            className="text-white"
                                        >
                                            {item.name}
                                        </p>
                                    );
                                })}
                            </AccordionBody>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </div>
    );
};

export default OnSmallDevices;
