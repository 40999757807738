import React from "react";
import { Col, Container, Row } from "reactstrap";
import AccuracyIcon from "../../assets/img/AccuracyIcon.svg";
import SecurityIcon from "../../assets/img/SecurityIcon.svg";
import TransparencyIcon from "../../assets/img/TransparencyIcon.svg";
import OurValueCard from "../cards/OurValueCard";

const OurValuesSection = () => {
    const arr = [
        {
            img: AccuracyIcon,
            name: "Accuracy",
            description:
                "We guarantee accurate billing to ensure timely and fair payment, prevent disputes, and build trust with patients and healthcare providers.",
        },
        {
            img: TransparencyIcon,
            name: "Transparency",
            description:
                "We value your trust, and prioritize transparency in every step of our billing practices so that patients can understand their bills, and healthcare providers can track the progress of their claims.",
        },
        {
            img: SecurityIcon,
            name: "Security",
            description:
                "We use robust security measures such as encryption and access controls to ensure that patient data remains private and secure. We take pride in complying with regulations to maintain the trust of our clients.",
        },
    ];
    return (
        <Container fluid className=" pb-5 ">
            <Container>
                <Row className="mb-5">
                    <div className="d-flex w-full justify-content-center  align-items-center">
                        <h1 className="text-site-primary mb-0 fw--600">
                            Our Values
                        </h1>
                        {/* <h5 className="text-site-dark-blue mb-0">Read more</h5> */}
                    </div>
                </Row>
                <Row className="my-3">
                    {arr.map((object, index) => {
                        return (
                            <Col
                                key={`OurValues-${index}`}
                                md={12}
                                lg={4}
                                className="mt-md-0 my-3"
                            >
                                <OurValueCard {...object} />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    );
};

export default OurValuesSection;
