import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

const GetStartedBtn = () => {
    const history = useHistory();
    return (
        <Button
            onClick={() => history.push("/about/contact-us")}
            className="btn btn-primary  rounded-pill info-button info-border  fs--20 fw--600 get-started "
        >
            Get started
        </Button>
    );
};

export default GetStartedBtn;
