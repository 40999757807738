import React from "react";
import { Button } from "reactstrap";

const InfoButton = ({ title, className, onClick }) => {
    return (
        <Button
            onClick={onClick}
            className={` fw--600 w-sm-100 rounded-pill px-2 px-md-5 py-2  my-4 fs--16 fs-lg--20  info-button info-border ${className}`}
        >
            {title}
        </Button>
    );
};

export default InfoButton;
