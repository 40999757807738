import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import CareerImage from "../../assets/img/careers-illustration.png";
import InfoButton from "../services/InfoButton";
export const CareerHero = () => {
    const history = useHistory();
    return (
        <Container className="py-5">
            <h3 className="text-center">Careers</h3>
            <Row>
                <Col>
                    <div className="d-flex flex-column justify-content-between h-100  at-anz-section ">
                        <p className="fs--16 fs-md--20">
                            At ANZ Billing, we are passionate about providing
                            top-notch medical billing services to our clients.
                            We are always looking for dedicated individuals who
                            share our commitment to excellence and want to join
                            our team. Whether you are an experienced medical
                            biller or coder or are just starting out in the
                            industry, we offer opportunities for growth and
                            development. Join us in making a positive impact on
                            the healthcare industry and the lives of patients.
                        </p>
                        <p className="border-bottom border-site-dark border-2 pb-4 fs--16 fs-md--20">
                            We offer a competitive salary, benefits package, and
                            flexible PTO and holidays.
                        </p>
                    </div>
                </Col>
                <Col lg={7} className="d-lg-block d-none">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div>
                            <img
                                src={CareerImage}
                                alt=""
                                // height={400}
                                className="img-fluid"
                            ></img>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="our-mission">
                    <p>Any questions?</p>
                    <InfoButton
                        onClick={() => history.push("/about/contact-us")}
                        title={"Contact Us"}
                    ></InfoButton>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};
