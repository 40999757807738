import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import LocationIcon from "../../assets/img/location.svg";
import EmailIcon from "../../assets/img/mail.svg";
import PhoneIcon from "../../assets/img/phone.svg";
import ContactForm from "./ContactForm";
import SuccessfullySubmited from "./SuccessfullySubmited";

const ContactDetails = ({ icon, text }) => (
    <div className="d-flex gap-3 mt-3">
        <img src={icon} alt={text} />
        {text}
    </div>
);

const OperationHours = ({ hours }) => (
    <>
        <h6 className="mb-3 fw-bold">Hours of Operation (UTC -5:00)</h6>
        {hours.map((item, index) => (
            <p key={item.day} className="mb-0">
                <span className="fw-medium">{item.day}:</span> {item.hours}
            </p>
        ))}
    </>
);

const FormSection = () => {
    const hours = [
        { day: "Monday", hours: "9:00am-5:00pm" },
        { day: "Tuesday", hours: "9:00am-5:00pm" },
        { day: "Wednesday", hours: "9:00am-5:00pm" },
        { day: "Thursday", hours: "9:00am-5:00pm" },
        { day: "Friday", hours: "9:00am-5:00pm" },
        { day: "Sunday", hours: "Closed" },
        { day: "Saturday", hours: "Closed" },
    ];
    const [isSubmitted, setIsSubmited] = useState(false);

    return (
        <Container className="py-5">
            <Row>
                <Col lg={6} xs={12}>
                    {isSubmitted ? (
                        <SuccessfullySubmited />
                    ) : (
                        <div
                            className="px-4 shadow py-5"
                            style={{ borderRadius: 20 }}
                        >
                            <ContactForm setIsSubmited={setIsSubmited} />
                        </div>
                    )}{" "}
                </Col>
                <Col lg={6} xs={12} className="d-flex flex-column mt-4">
                    <div
                        className="align-self-end d-lg-block d-none  border p-5"
                        style={{ borderRadius: 20 }}
                    >
                        <OperationHours hours={hours} />
                        <h6 className="mt-5 fw-bold">
                            Feel free to reach out!
                        </h6>
                        <ContactDetails
                            icon={PhoneIcon}
                            text=<h6 className="mb-0">
                                Phone: +1-866-282-5040 <br></br>Fax:
                                +1-609-482-8118
                            </h6>
                        />
                        <ContactDetails
                            icon={EmailIcon}
                            text=<h6 className="mb-0">anz.billing@gmail.com</h6>
                        />
                        <ContactDetails
                            icon={LocationIcon}
                            text=<h6 className="mb-0">
                                P.O Box 203 <br></br>Princeton Junction, NJ{" "}
                                <br></br>08550
                            </h6>
                        />
                    </div>

                    <div className="align-self-start d-lg-none d-block   p-5">
                        <div className="d-flex justify-content-center align-items-center">
                            <div
                                style={{
                                    flex: 1,
                                    height: "1px",
                                    backgroundColor: "#cdd0d6",
                                }}
                            />

                            <div>
                                <p
                                    className="mb-0 mx-2"
                                    // style={{
                                    //     width: "70px",
                                    //     textAlign: "center",
                                    // }}
                                >
                                    or
                                </p>
                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    height: "1px",
                                    backgroundColor: "#cdd0d6",
                                }}
                            />
                        </div>
                        <h6 className=" fw-bold">Contact us directly</h6>
                        <ContactDetails
                            icon={PhoneIcon}
                            text=<h6 className="mb-0">
                                Phone: 1-866-282-5040 <br></br>Fax: 609-482-8118
                            </h6>
                        />
                        <ContactDetails
                            icon={EmailIcon}
                            text=<h6 className="mb-0">Anz.billing@gmail.com</h6>
                        />
                        <ContactDetails
                            icon={LocationIcon}
                            text=<h6 className="mb-0">
                                P.O Box 203 <br></br>Princeton Junction, NJ{" "}
                                <br></br>08550
                            </h6>
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default FormSection;
