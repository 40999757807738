import React from "react";
import { useHistory } from "react-router-dom";

const MenuItem = ({ to,toggleMenu, children }) => {
    const history = useHistory();


    const handleClick = () => {
        toggleMenu();
        history.push(to);
    };

    return (
        <div role="button" onClick={handleClick} className="p-2">
            <h5 className="fw--500 fs--18 nav-item-hover-header">{children}</h5>
        </div>
    );
};

export default MenuItem;
