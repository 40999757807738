import React from "react";

const InfoText = ({ className, children }) => {
    return (
        <p className={`fs--19 fw--500 my-4 text-site-primary ${className}`}>
            {children}
        </p>
    );
};

export default InfoText;
