import React from "react";

const OurValueCard = ({ img, name, description }) => {
    return (
        <div className="p-2 p-sm-5 text-center h-100  home__ourvalues-card">
            <div className="">
                <img
                    height={70}
                    width={70}
                    alt=""
                    src={img}
                    className="rounded-circle "
                ></img>
            </div>
            <p className="fw--600">{name}</p>

            <p className="fw--500 text-justify overflow-hidden">
                {description}{" "}
            </p>
        </div>
    );
};

export default OurValueCard;
