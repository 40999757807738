import React from "react";

const PaperPen = () => {
    return (
        <svg
            className="my-4"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00044 47.5042H35.0031C36.384 47.5042 37.5033 46.3849 37.5033 45.004V5.00044C37.5033 3.61961 36.384 2.50022 35.0031 2.50022H5.00044C3.61961 2.50022 2.50022 3.61961 2.50022 5.00044V45.004C2.50022 46.3849 3.61961 47.5042 5.00044 47.5042ZM35.0031 50.0044H5.00044C2.23877 50.0044 0 47.7656 0 45.004V5.00044C0 2.23877 2.23877 0 5.00044 0H35.0031C37.7647 0 40.0036 2.23877 40.0036 5.00044V45.004C40.0036 47.7656 37.7647 50.0044 35.0031 50.0044Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5015 11.2511C17.5015 10.5607 18.0611 10.001 18.7516 10.001H33.7529C34.4433 10.001 35.003 10.5607 35.003 11.2511C35.003 11.9415 34.4433 12.5012 33.7529 12.5012H18.7516C18.0611 12.5012 17.5015 11.9415 17.5015 11.2511Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5015 16.2511C17.5015 15.5607 18.0611 15.001 18.7516 15.001H33.7529C34.4433 15.001 35.003 15.5607 35.003 16.2511C35.003 16.9415 34.4433 17.5012 33.7529 17.5012H18.7516C18.0611 17.5012 17.5015 16.9415 17.5015 16.2511Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5015 26.2521C17.5015 25.5616 18.0611 25.002 18.7516 25.002H33.7529C34.4433 25.002 35.003 25.5616 35.003 26.2521C35.003 26.9425 34.4433 27.5022 33.7529 27.5022H18.7516C18.0611 27.5022 17.5015 26.9425 17.5015 26.2521Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5015 31.253C17.5015 30.5626 18.0611 30.0029 18.7516 30.0029H33.7529C34.4433 30.0029 35.003 30.5626 35.003 31.253C35.003 31.9435 34.4433 32.5032 33.7529 32.5032H18.7516C18.0611 32.5032 17.5015 31.9435 17.5015 31.253Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.50071 27.5022V31.2525H11.251V27.5022H7.50071ZM6.2506 25.002H12.5012C13.1916 25.002 13.7513 25.5616 13.7513 26.2521V32.5026C13.7513 33.1931 13.1916 33.7527 12.5012 33.7527H6.2506C5.56019 33.7527 5.00049 33.1931 5.00049 32.5026V26.2521C5.00049 25.5616 5.56019 25.002 6.2506 25.002Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6352 10.3671C15.1234 10.8553 15.1234 11.6468 14.6352 12.135L8.75082 18.0194L5.36663 14.6352C4.87844 14.1471 4.87844 13.3555 5.36663 12.8673C5.85484 12.3792 6.64636 12.3792 7.13457 12.8673L8.75082 14.4836L12.8673 10.3671C13.3555 9.87893 14.1471 9.87893 14.6352 10.3671Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.5044 25.0022H45.0041V44.1705L46.2542 45.8374L47.5044 44.1705V25.0022ZM50.0046 45.004L46.2542 50.0044L42.5039 45.004V22.502H50.0046V45.004Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.5044 16.2512H45.0041V18.7514H47.5044V16.2512ZM45.0041 13.751H47.5044C48.8852 13.751 50.0046 14.8703 50.0046 16.2512V21.2516H42.5039V16.2512C42.5039 14.8703 43.6233 13.751 45.0041 13.751Z"
                fill="#8EBDF3"
            />
        </svg>
    );
};

export default PaperPen;
