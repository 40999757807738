import React, { useState } from "react";
import { Container } from "reactstrap";
import ApplyJobModal from "../common/ApplyJobModal";
import InfoButton from "../services/InfoButton";

export const PostingJob = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <Container className="bg-site-light-sky py-5" fluid>
            {" "}
            <Container>
                {" "}
                <div className="mb-4 our-mission">
                    <h5 className="mb-4">Join our team</h5>
                    <p className="w-50  fw--600 mb-1">
                        Medical Biller and Coder
                    </p>
                    <p className="w-50  mb-0">
                        Enthusiastic & Creative? Apply Now!{" "}
                    </p>
                    {/* <p className="fw--600 mt-1 mb-1">Read more</p> */}

                    <InfoButton title={"Apply"} onClick={toggle}></InfoButton>
                </div>
                <hr className="text-site-light-blue"></hr>
                {/* <div className="my-5 our-mission">
                    <h5 className="mb-4">Team Leader</h5>
                    <p className="w-50  mb-0">
                        Job description Job description Job description Job
                        description Job description Job description Job
                        description Job description Job description Job
                        description Job description Job description{" "}
                    </p>
                    <p className="w-50  fw--600 mt-1 mb-1">Read more</p>
                    <Button
                        outline
                        onClick={toggle}
                        className="border border-site-dark-blue text-site-dark-blue bg-white rounded-pill  w-25 fw--500 mt-1 "
                    >
                        Apply
                    </Button>
                </div> */}
            </Container>
            <ApplyJobModal modal={modal} toggle={toggle} />
        </Container>
    );
};
