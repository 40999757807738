import React, { useState } from "react";
import { BiPhone } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import Logo from "../../assets/img/Logo_01b.png";
import DropMenu from "./DropMenu";
import MenuItem from "./MenuItem";

const Header = ({ toggleSideBar }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const isActiveNav = (path) => (pathname === path ? "active-nav" : "");
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (dropdown) => {
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    return (
        <Container className="py-4">
            <Row className="d-flex align-items-center">
                <Col xs={7} lg={3}>
                    <div
                        className="d-none d-xl-flex align-items-center gap-2 "
                        role="button"
                        onClick={() => history.push("/")}
                    >
                        <img height={60} src={Logo} alt="logo" />
                        {/* <h5 className="fw--700 fs--24 ">ANZ Billing</h5> */}
                    </div>
                    <div className="d-flex d-xl-none align-items-center justify-content-between gap-2">
                        <i
                            className="fas fa-bars fa-2x  cursor-pointer"
                            onClick={toggleSideBar}
                        ></i>
                        {/* <h5 className="fw--700 fs--20 mt-2">ANZ Billing</h5> */}
                        <img height={40} src={Logo} alt="logo" />
                    </div>
                </Col>
                <Col xs={5} lg={9}>
                    <div className="d-none d-xl-flex align-items-center justify-content-end gap-5 ">
                        {" "}
                        <h5
                            className={`cursor-pointer ${isActiveNav("/")}`}
                            onClick={() => history.push("/")}
                        >
                            Home
                        </h5>
                        <DropMenu
                            menuText={"Services"}
                            isOpen={openDropdown === "dropdown1"}
                            toggleMenu={() => toggleDropdown("dropdown1")}
                        >
                            {servicesRoutes.map(({ path, title }) => (
                                <MenuItem
                                    key={`header-${path}`}
                                    to={path}
                                    toggleMenu={() => toggleDropdown(null)}
                                >
                                    {title}
                                </MenuItem>
                            ))}
                        </DropMenu>
                        {/* <DropMenu
                            menuText={"About"}
                            isOpen={openDropdown === "dropdown2"}
                            toggleMenu={() => toggleDropdown("dropdown2")}
                        >
                            {aboutRoutes.map(({ path, title }) => (
                                <MenuItem
                                    key={`header-${path}`}
                                    to={path}
                                    toggleMenu={() => toggleDropdown(null)}
                                >
                                    {title}
                                </MenuItem>
                            ))}
                        </DropMenu> */}
                        <h5
                            className={`cursor-pointer ${isActiveNav(
                                "/about/careers"
                            )}`}
                            onClick={() => history.push("/about/careers")}
                        >
                            Careers
                        </h5>
                        <h5
                            className={`cursor-pointer ${isActiveNav(
                                "/about/our-mission"
                            )}`}
                            onClick={() => history.push("/about/our-mission")}
                        >
                            Our Mission
                        </h5>
                        <h5
                            className={`cursor-pointer ${isActiveNav(
                                "/resources"
                            )}`}
                            onClick={() => history.push("/resources")}
                        >
                            Resources
                        </h5>
                        <Button
                            onClick={() => history.push("/about/contact-us")}
                            className="btn btn-primary  rounded-pill info-button info-border  fs--20 fw--600 get-started "
                        >
                            Contact us
                        </Button>
                    </div>
                    <div className="d-flex d-xl-none justify-content-end ">
                        <Button
                            onClick={() => history.push("/about/contact-us")}
                            className="btn btn-primary d-flex align-items-center gap-1  rounded-pill info-button info-border px-2 fw--600 "
                        >
                            <BiPhone size={22} /> Contact
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;

export const servicesRoutes = [
    {
        path: "/services/revenue-cycle-management",
        title: "Revenue Cycle Management",
    },
    {
        path: "/services/web-development",
        title: "Web design and development",
    },
    {
        path: "/services/practice-management",
        title: "Practice Management",
    },
    {
        path: "/services/medical-billing",
        title: "Medical Billing and Coding",
    },
    {
        path: "/services/patient-experience-management",
        title: " Patient Experience Management",
    },
    {
        path: "/services/credentialing",
        title: "Credentialing",
    },
];

export const aboutRoutes = [
    {
        path: "/about/our-mission",
        title: "Our Mission",
    },
    {
        path: "/about/careers",
        title: "Careers",
    },
    {
        path: "/about/contact-us",
        title: "Contact Us",
    },
];
