import React from "react";
import { servicesImages } from "../../assets/img/Services";

const CheckedText = ({ children }) => {
    return (
        <div className="d-flex align-items-start my-4 gap-4" >
            <img src={servicesImages.CheckInCircle} alt="" className="img-sm-32 mt-1"/>
            <p className=" fs--20 fs-md--22  fw--500 w-100 text-left text-md-left line-sm-height-2">{children}</p>
        </div>
    );
};

export default CheckedText;
