import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Logo from "../../assets/img/Logo_01b White.png";
import OnLargeDevices from "./OnLargeDevices";
import OnSmallDevices from "./OnSmallDevices";

const Footer = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Set up event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <Container className="bg-site-primary text-white  footer" fluid>
            <Container>
                <Row xs={1} sm={2} md={4} className="mt- pt-md-5 pt-4 mb-5">
                    <Col className="mb-md-0 mb-3">
                        <img
                            width={250}
                            className="img-fluid"
                            src={Logo}
                            alt=""
                        />
                    </Col>
                    {isMobile ? (
                        <OnSmallDevices data={sections} />
                    ) : (
                        <OnLargeDevices data={sections} />
                    )}
                </Row>

                <Row>
                    <Col>
                        <p className="fs--16 text-site-lite">
                            Copyright © 2024, ANZ Billing Inc.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Footer;
const sections = [
    {
        title: "Services",
        items: [
            {
                name: "RCM",
                link: "/services/revenue-cycle-management",
            },
            {
                name: "Patient Experience",
                link: "/services/patient-experience-management",
            },
            {
                name: "Practice Management",
                link: "/services/practice-management",
            },
            {
                name: "Credentialing",
                link: "/services/credentialing",
            },
            {
                name: "Medical Billing & Coding",
                link: "/services/medical-billing",
            },
        ],
    },
    {
        title: "Resources",
        items: [
            {
                name: "Policy Updates",
                link: "",
            },
            {
                name: "FAQ",
                link: "/resources?scrollTo=faq",
            },
        ],
    },
    {
        title: "Company",
        items: [
            {
                name: "About",
                link: "",
            },
            {
                name: "Policy Contact",
                link: "",
            },
            {
                name: "Privacy Policy",
                link: "",
            },
            {
                name: "Terms of  service",
                link: "",
            },
            {
                name: "Careers",
                link: "/about/careers",
            },
        ],
    },
];
