import FinancialHealth from "../components/home/FinancialHealth";
import HeroSection from "../components/home/HeroSection";
import OurServices from "../components/home/OurServices";
import OurValuesSection from "../components/home/OurValuesSection";
import TestimonialsSection from "../components/home/TestimonialsSection";
import WhyMedicalSection from "../components/home/WhyMedicalSection";

const Home = (props) => {
    return (
        <>
            <HeroSection />
            <OurServices />
            <OurValuesSection />
            <WhyMedicalSection />
            {/* <StayUpdatedSection /> */}
            <TestimonialsSection />
            <FinancialHealth />
        </>
    );
};

export default Home;
