import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { servicesImages } from "../../assets/img/Services";
import InfoButton from "../../components/services/InfoButton";
import InfoText from "../../components/services/InfoText";
import ServiceHeroSection from "../../components/services/ServiceHeroSection";

const MedicalBilling = () => {
    const history = useHistory();

    return (
        <div>
            <ServiceHeroSection
                smScreenCol={["col-3", "col-9"]}
                title="Medical Billing and Coding"
                text="Accurate medical coding is essential for ensuring that your practice receives proper reimbursement for the services you provide. Our team of certified coders uses the latest coding guidelines to ensure that your claims are submitted correctly and promptly."
                textSmScreen="Comprehensive revenue cycle management solution that allows you to shift the burden of collections off your shoulders, so you can focus on patient care."
                image={servicesImages.MedicalBilling}
            />
            <Container className="px-3 px-md-5 py-5">
                <h1>AAPC Certified Coders</h1>
                {dataContent.map(({ para, imgContent, id }) => (
                    <Row key={`medical-billing-${id}`} className="my-5 ">
                        <Col sm={12} lg={5}>
                            <p className="fs--22">{para}</p>
                        </Col>
                        <Col
                            sm={12}
                            lg={7}
                            className="d-flex justify-content-between align-items-start img-concise px-md-5"
                        >
                            {imgContent.map(({ text, icon, id }) => (
                                <div
                                    key={`img-content-${id}`}
                                    className="text-center mt-5 pt-5"
                                >
                                    <img src={icon} alt="" />
                                    <p className="mt-3 fw--600">{text}</p>
                                </div>
                            ))}
                        </Col>
                    </Row>
                ))}
            </Container>
            <Container fluid className="">
                <Container className="bg-site-secondary rounded py-5 mt-5 ">
                    {/* <InfoSection> */}
                    <Row className="align-items-center ">
                        <Col sm={12} lg={6} className=" ps-md-5 pe-md-4">
                            <h3 className="fw--600">
                                Credentialing is just one piece of the puzzle
                                when it comes to running a successful medical
                                practice, but it's a crucial one.
                            </h3>
                            <InfoText className={"d-none d-lg-block"}>
                                Let us take care of the paperwork and
                                administrative tasks so you can focus on
                                providing the best care possible to your
                                patients.
                            </InfoText>
                            <InfoButton
                                onClick={() =>
                                    history.push("/about/contact-us")
                                }
                                className={"d-none d-lg-block"}
                                title={"Learn more"}
                            />
                        </Col>
                        <Col
                            sm={10}
                            lg={6}
                            className="d-flex justify-content-end "
                        >
                            <img
                                src={servicesImages.CredentialingPeople}
                                className="pe-md-5 img-fluid w-50"
                                alt=""
                            />
                        </Col>
                        {/* <InfoButton className={"d-lg-none"} title={"Learn more"} /> */}
                    </Row>
                    {/* </InfoSection> */}
                </Container>
            </Container>

            {/* <TestimonialPFP
                img={servicesImages.TestimonialPFP}
                name={"Name"}
                title={"title"}
                description={
                    "We have worked with Salma and her team for over 5 years and it is like working with your family.  They take pride in their work and also make sure that your reputation is cared for as well.  We have blind faith that she is handling our accounts well and tries her best to get every last dollar from the insurance company.  We have worked with a large billing company before and they would make several sloppy mistakes costing us thousands of dollars and their support staff was horrendous.  Working with ANZ is like having another partner in your business"
                }
            /> */}
        </div>
    );
};

export default MedicalBilling;

const dataContent = [
    {
        id: 1,
        para: "Our medical coding services are designed to help you optimize your revenue cycle by ensuring that all codes are accurate and up-to-date, and that your claims are submitted in a timely manner. We use advanced coding software and tools to streamline the coding process and minimize errors, which can lead to faster reimbursements and improved cash flow.",
        imgContent: [
            {
                id: "m-1",
                icon: servicesImages.Verification,
                text: "Verification",
            },
            {
                id: "m-2",
                icon: servicesImages.PaymentPosting,
                text: "Payment Posting",
            },
            {
                id: "m-3",
                icon: servicesImages.ClaimSubmission,
                text: "Claims Submission",
            },
        ],
    },
    {
        id: 2,
        para: "Our medical billing services are designed to help you get paid faster and more efficiently by ensuring that all claims are submitted accurately and on time, and that any denials or rejections are quickly resolved. We have extensive experience working with insurance companies and other payers, and we know how to navigate the complex billing process to ensure that you receive the maximum reimbursement for your services.",
        imgContent: [
            {
                id: "b-1",
                icon: servicesImages.GirlWithHeart,
                text: "Verification",
            },
            {
                id: "b-2",
                icon: servicesImages.ArFollowUp,
                text: "Payment Posting",
            },
            {
                id: "b-3",
                icon: servicesImages.Telephone,
                text: "Claims Submission",
            },
        ],
    },
];
