import React from "react";

const Screen = () => {
    return (
        <svg
            className="my-4"
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
        >
            <path
                d="M6.32731 0.000113327H51.6727L51.7055 0C53.4435 0 55.0166 0.7054 56.1544 1.84546L56.1545 1.84557C57.2951 2.99577 58 4.57961 58 6.32821V6.35517V6.35376V41.5941C58 43.3424 57.2951 44.9259 56.1541 46.0761L56.1544 46.0758C55.0165 47.2159 53.4434 47.9213 51.7055 47.9213H6.29445C4.5565 47.9213 2.98337 47.216 1.84563 46.0759L1.84552 46.0758C0.70489 44.9255 0 43.3417 0 41.5931V41.5661V41.5675V6.3537V6.32708C0 4.57887 0.70489 2.99532 1.84591 1.84512L1.84552 1.84552C2.98342 0.705344 4.5565 0 6.2944 0L6.32895 0.000169936H6.32725L6.32731 0.000113327ZM46.6109 2.63644H6.31734C5.30121 2.63644 4.38176 3.04964 3.71754 3.71714L3.71743 3.71737C3.04947 4.3889 2.63656 5.31463 2.63656 6.33699V6.35455V6.3537V41.591C2.63656 42.6137 3.04947 43.5399 3.71765 44.2119L3.71748 44.2117C4.38188 44.8793 5.30138 45.2926 6.31745 45.2926H51.683C52.6991 45.2926 53.6186 44.8794 54.2828 44.2119L54.283 44.2117C54.951 43.5401 55.3639 42.6143 55.3639 41.5921V41.5744V41.5753V6.35359V6.3379C55.3639 5.31531 54.951 4.38913 54.2828 3.71714L54.283 3.71726C53.6187 3.04958 52.6991 2.63633 51.6831 2.63633H46.6112L46.6109 2.63644ZM30.3183 56.6817H27.6819V46.6108H30.3182L30.3183 56.6817ZM41.5755 57.9999H16.4246V55.3636H41.5755V57.9999ZM56.6817 37.8582H1.31819V35.2218H56.6817L56.6817 37.8582ZM28.9999 29.34L28.644 29.2399C23.718 27.7973 20.1753 23.3346 20.1417 18.0392V11.0545L28.9999 8.51567L37.9398 11.0519V17.9801C37.9173 23.3224 34.3308 27.821 29.436 29.2224L29.3532 29.2427L28.9999 29.34ZM22.7782 13.0422V18.0328C22.8041 22.0027 25.3728 25.3657 28.9361 26.5768L29.0001 26.5957C32.6748 25.3821 35.2832 21.9867 35.301 17.9796V13.0448L29.0001 11.2572L22.7782 13.0422Z"
                fill="#8EBDF3"
            />
        </svg>
    );
};

export default Screen;
