import Home from "../views/Home";
import Resources from "../views/Resources";
import Credentialing from "../views/Services/Credentialing";
import MedicalBilling from "../views/Services/MedicalBilling";
import PatientExperience from "../views/Services/PatientExperience";
import PracticeManagement from "../views/Services/PracticeManagement";
import RevenueCycleManagement from "../views/Services/RevenueCycleManagement";
import WebsiteDevelopment from "../views/Services/WebsiteDevelopment";
import Careers from "../views/about/Careers";
import ContactUs from "../views/about/ContactUs";
import OurMission from "../views/about/OurMission";
import AuthView from "../views/auth/AuthView";

let routes = [
    {
        path: "/auth",
        component: AuthView,
        layout: "auth",
    },
    {
        path: "/",
        component: Home,
        layout: "main",
    },
    {
        path: "/services/revenue-cycle-management",
        layout: "main",
        component: RevenueCycleManagement,
    },
    {
        path: "/services/web-development",
        layout: "main",
        component: WebsiteDevelopment,
    },
    {
        path: "/services/practice-management",
        layout: "main",
        component: PracticeManagement,
    },
    {
        path: "/services/medical-billing",
        layout: "main",
        component: MedicalBilling,
    },
    {
        path: "/services/patient-experience-management",
        layout: "main",
        component: PatientExperience,
    },
    {
        path: "/services/credentialing",
        layout: "main",
        component: Credentialing,
    },
    {
        path: "/about/our-mission",
        component: OurMission,
        layout: "main",
    },
    {
        path: "/about/careers",
        component: Careers,
        layout: "main",
    },
    {
        path: "/about/contact-us",
        component: ContactUs,
        layout: "main",
    },
    {
        path: "/resources",
        component: Resources,
        layout: "main",
    },
];
export default routes;
