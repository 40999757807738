import React, { useState } from "react";
import { Col, Collapse, Container, Row, Button } from "reactstrap";
import { servicesImages } from "../../assets/img/Services";
import CheckedText from "../../components/services/CheckedText";
import InfoSection from "../../components/services/InfoSection";
import InfoText from "../../components/services/InfoText";
import ServiceHeroSection from "../../components/services/ServiceHeroSection";
import InfoButton from "../../components/services/InfoButton";
import { useHistory } from "react-router-dom";
import GetStartedBtn from "../../components/common/GetStartedBtn";

const PracticeManagement = () => {
    const history = useHistory();
    const [openCollapse, setOpenCollapse] = useState(
        Array(dataContent.length).fill(false)
    );

    const toggle = (index) => {
        const newOpenCollapse = openCollapse.map((value, i) =>
            i === index ? !value : false
        );
        setOpenCollapse(newOpenCollapse);
    };
    return (
        <div>
            <ServiceHeroSection
                smScreenCol={["col-3", "col-9"]}
                title="Practice Management "
                text="Get a customized practice management solution that works for your unique needs! We understand that every medical practice is different. That's why we offer customized practice management solutions that are tailored to your unique needs."
                image={servicesImages.MaleFemaleDoctor}
            />
            <Container className="d-none d-md-block ">
                <Row className="align-items-start gap-5">
                    {dataContent.map((content, index) => (
                        <Col
                            key={`pm-${index}`}
                            md="6"
                            lg="4"
                            className="my-5 mx-auto"
                        >
                            <img src={content.icon} alt="" />
                            <h5 className="my-4 fs--22 fw--500">
                                {content.title}
                            </h5>
                            <p className="">{content.text}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container fluid className="px-4 py-5    d-md-none ">
                <Row className="align-items-start">
                    {dataContent.map((content, index) => (
                        <Col
                            key={`pm-${index}`}
                            md="4"
                            className="my-3 mx-auto"
                        >
                            <div
                                className="d-flex gap-3"
                                onClick={() => toggle(index)}
                            >
                                <img src={content.icon} alt="" />
                                <h5 className="my-4 fs--16 fw--500">
                                    {content.title}{" "}
                                    <i
                                        className={`fas ms-1 fa-chevron-${
                                            openCollapse[index] ? "up" : "down"
                                        } text-dark`}
                                    ></i>
                                </h5>
                            </div>
                            <Collapse isOpen={openCollapse[index]}>
                                <p className="mt-3">{content.text}</p>
                            </Collapse>
                        </Col>
                    ))}
                </Row>
            </Container>

            <Container fluid className="mx-2">
                <Container className="bg-site-secondary rounded py-5 my-5 ">
                    {/* <InfoSection> */}
                    <Row className="align-items-center ">
                        <Col sm={12} lg={6} className=" ps-md-5 pe-md-4">
                            <h3 className="fw--600">
                                We help medical practices optimize their revenue
                                cycle, ensuring timely payment and a steady cash
                                flow.{" "}
                            </h3>
                            <InfoText className={"d-none d-lg-block"}>
                                Our expert team is here to guide practices every
                                step of the way, making sure they maintain a
                                healthy bottom line and keep patients happy and
                                healthy.
                            </InfoText>
                            <InfoButton
                                onClick={() =>
                                    history.push("/about/contact-us")
                                }
                                className={"d-none d-lg-block"}
                                title={"Learn more"}
                            />
                        </Col>
                        <Col
                            sm={10}
                            lg={6}
                            className="d-flex justify-content-end "
                        >
                            <img
                                src={servicesImages.RevenueGraphBg}
                                className="pe-md-5 img-fluid w-50"
                                alt=""
                            />
                        </Col>
                        {/* <InfoButton className={"d-lg-none"} title={"Learn more"} /> */}
                    </Row>
                    {/* </InfoSection> */}
                </Container>
            </Container>

            {/* <InfoSection className={"mb-0"}>
                <Row>
                    <Col sm={12} lg={6} className="ps-md-5 pe-md-4 py-5">
                        <h3 className={"text-center text-lg-left fw--600"}>
                            We help medical practices optimize their revenue
                            cycle, ensuring timely payment and a steady cash
                            flow.{" "}
                        </h3>
                        <InfoText className={"text-center text-lg-left"}>
                            Our expert team is here to guide practices every
                            step of the way, making sure they maintain a healthy
                            bottom line and keep patients happy and healthy.
                        </InfoText>
                        <InfoButton title={"Learn more"} className={"mb-4"} />
                    </Col>
                    <Col
                        sm={10}
                        lg={6}
                        className="d-none d-lg-flex justify-content-end position-relative "
                    >
                        <div className="position-absolute img-position">
                            <img
                                src={servicesImages.RevenueGraphBg}
                                className="pe-md-5 img-fluid "
                                alt=""
                            />
                        </div>
                    </Col>
                </Row>
            </InfoSection> */}
            {/* <div className="d-none d-lg-block mask"></div> */}
            {/* <Container className="d-lg-none">
                <img
                    src={servicesImages.RevenueGraphBg}
                    className="px-5 pt-5  img-fluid "
                    alt=""
                />
                <div className="mask-sm"></div>
            </Container> */}

            <Container className="my-5 py-5 ">
                <Row className="justify-content-between mx-2">
                    <Col lg={5} className="p-0 m-0 my-3 ">
                        <p className="fw--600 fs--26 line-sm-height-2-5  line-sm-height-2">
                            Ditch dealing with complex billing and
                            administrative tasks - Let us take the burden off
                            your shoulders.
                        </p>
                        <p className="fs--18 mt-2">
                            Simplify your practice: Outsource complex billing
                            and admin tasks. Click "Get Started" to optimize
                            your revenue cycle now!
                        </p>
                        <GetStartedBtn />
                    </Col>
                    <Col lg={5} className="p-0 m-0">
                        <CheckedText>Maximize revenue</CheckedText>
                        <CheckedText>Reduce denials</CheckedText>
                        <CheckedText>Improve patient satisfaction</CheckedText>
                    </Col>
                </Row>
            </Container>

            {/* <TestimonialPFP
                img={servicesImages.TestimonialPFP}
                name={"Rosemarie A Osowik"}
                title={"MD"}
                description={
                    "ANZ helped me make a very successful transition from hospital employed to private practice! ANZ’s expertise allowed us to achieve a highest collection rate I have ever had! ANZ is always accessible to my staff to answer urgent eligibility and billing questions. "
                }
            /> */}
        </div>
    );
};

export default PracticeManagement;

const dataContent = [
    {
        title: "Credentialing and Contracting",
        // text: "Provider credentialing is an important but arduous process that benefits from an experienced professional who understands the lay of the land. Credentialing and contracting are the foundation for profitable practice. credentialing may be frustrating, but it is necessary. We alleviate the headache, leaving you with just a positive outcome leading you to the path of success. After initial provider enrollment through the credentialing phase to eventual re-credentialing down the line.",
        text: "Streamline provider credentialing for profitable practice. From enrollment to re-credentialing, we ensure a positive outcome.",
        icon: servicesImages.CredsEnrollment,
    },
    {
        title: "ERA EFT Set-Up",
        text: "Our electronic remittance advice (ERA) and electronic funds transfer (EFT) services make payment posting fast, easy, and accurate.",
        icon: servicesImages.EFT,
    },
    {
        title: "Customer Service",
        text: "Our dedicated customer service team is always available to answer any questions or concerns you may have about our services or your account.",
        icon: servicesImages.CustomerServices,
    },
    {
        title: "Fee Schedule",
        text: "Our team of experts analyzes and sets up fee schedules to ensure maximum reimbursement for your practice.",
        icon: servicesImages.FeesSchedule,
    },
    {
        title: "Prior Authorization",
        text: "Our experienced team handles prior authorization requests for medications, procedures, and other treatments, reducing delays and increasing revenue.",
        icon: servicesImages.PriorAuthorization,
    },
    {
        title: "Patient Satisfaction",
        text: "Our patient satisfaction program ensures that your patients receive clear, concise billing statements and exceptional customer service to improve collections and patient retention.",
        icon: servicesImages.PatientSatisfaction,
    },
];
