import React from "react";

const CheckMark = () => {
    return (
        <svg
            className="my-4"
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="55"
            viewBox="0 0 41 55"
            fill="none"
        >
            <path
                d="M32.5665 25.3956C33.1266 24.8355 33.1266 23.9274 32.5665 23.3673C32.0065 22.8073 31.0983 22.8073 30.5383 23.3673L20.0787 33.8269L15.356 29.1042C14.796 28.5441 13.8878 28.5441 13.3278 29.1042C12.7677 29.6642 12.7677 30.5724 13.3278 31.1324L20.0787 37.8834L32.5665 25.3956Z"
                fill="#8EBDF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.1579 14.3421V44.4605C40.1579 46.8369 38.2316 48.7632 35.8552 48.7632H10.0394C7.6631 48.7632 5.73682 46.8369 5.73682 44.4605V4.30263C5.73682 1.92636 7.6631 0 10.0394 0H25.8158L40.1579 14.3421ZM25.8158 15.7763C25.0236 15.7763 24.3816 15.1342 24.3816 14.3421V2.86842H10.0394C9.24733 2.86842 8.60524 3.51055 8.60524 4.30263V44.4605C8.60524 45.2526 9.24733 45.8947 10.0394 45.8947H35.8552C36.6474 45.8947 37.2894 45.2526 37.2894 44.4605V15.7763H25.8158ZM27.25 5.49078L34.6671 12.9079H27.25V5.49078Z"
                fill="#8EBDF3"
            />
            <path
                d="M2.86842 8.60547V47.3292C2.86842 49.7055 4.79471 51.6318 7.17105 51.6318H34.4211V54.5002H7.17105C3.21062 54.5002 0 51.2896 0 47.3292V8.60547H2.86842Z"
                fill="#8EBDF3"
            />
        </svg>
    );
};

export default CheckMark;
