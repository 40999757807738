import React from "react";

const TestimonialCard = ({ img, name, description, imageAlign }) => {
    return (
        <div className="px-2 py-4 p-md-5  text-center h-100  home__testimonial-card ">
            <div
                className={`${
                    imageAlign === "center"
                        ? "d-flex justify-content-center"
                        : ""
                }`}
            >
                <img
                    height={70}
                    width={70}
                    alt=""
                    src={img}
                    className="rounded-circle "
                ></img>
            </div>
            <p className="fw--600">{name}</p>

            <p
                className="fw--500 text-justify overflow-hidden"
                style={{ fontStyle: "italic" }}
            >
                {description}{" "}
            </p>
        </div>
    );
};

export default TestimonialCard;
