// Assuming the same initial setup with React, react-icons, and Reactstrap

import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
} from "reactstrap";
import { FaStar, FaRegStar } from "react-icons/fa";

// Expanded mock data array with more reviews and avatars
const reviews = [
    {
        id: 1,
        name: "John Doe",
        review: "This product is amazing! I've seen a significant improvement in my daily routine.",
        rating: 5,
        avatar: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
        id: 2,
        name: "Jane Smith",
        review: "Absolutely love it! The customer service was fantastic as well. Highly recommend.",
        rating: 4,
        avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
        id: 3,
        name: "Emily Johnson",
        review: "Good quality, but I found it a bit pricey compared to other options.",
        rating: 3,
        avatar: "https://randomuser.me/api/portraits/women/50.jpg",
    },
    {
        id: 4,
        name: "Michael Brown",
        review: "Didn’t fit my needs. The product was okay, just not what I expected.",
        rating: 2,
        avatar: "https://randomuser.me/api/portraits/men/15.jpg",
    },
    {
        id: 5,
        name: "Rachel Green",
        review: "Fantastic! This is exactly what I was looking for. Great quality!",
        rating: 5,
        avatar: "https://randomuser.me/api/portraits/women/22.jpg",
    },
    {
        id: 6,
        name: "Ross Geller",
        review: "It's alright. Not great, but not terrible either.",
        rating: 3,
        avatar: "https://randomuser.me/api/portraits/men/34.jpg",
    },
];

const CustomerReviews = () => {
    const renderRating = (rating) => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            i <= rating
                ? stars.push(<FaStar key={i} className="text-warning" />)
                : stars.push(<FaRegStar key={i} className="text-warning" />);
        }
        return stars;
    };

    return (
        <Container className="mt-5 pt-5">
            <Row>
                <Col>
                    <h1 className="text-center fw--600 my-5 ">
                        Customer Reviews
                    </h1>
                </Col>
            </Row>
            <Row>
                {reviews.map((review) => (
                    <Col md="4" sm="6" className="mb-4" key={review.id}>
                        <Card className="h-100 review-card cursor-pointer">
                            <CardBody>
                                <div className="review-avatar mb-3">
                                    <img
                                        src={review.avatar}
                                        alt={review.name}
                                        className="rounded-circle"
                                    />
                                </div>
                                <CardTitle tag="h5">{review.name}</CardTitle>
                                <CardText>{review.review}</CardText>
                                <CardText>
                                    {renderRating(review.rating)}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default CustomerReviews;
