import React from "react";
import { CareerHero } from "../../components/about/CareerHero";
import { PostingJob } from "../../components/about/PostingJob";

const Careers = () => {
    return (
        <>
            <CareerHero />
            <PostingJob />
        </>
    );
};

export default Careers;
