import { useState } from "react";
import { Collapse, Container } from "reactstrap";
const OurHistory = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    return (
        <Container className="talentedstaff-container" fluid>
            {" "}
            <Container>
                {/* <div className="d-none d-lg-block"> */}
                <div>
                    <h2 className="mb-3 mt-5">Our History</h2>
                    <p className="w-lg-75 fs--20" style={{ lineHeight: 1.6 }}>
                        Established in 2009, ANZ Billing has rapidly grown into
                        a trusted name in medical billing. Our teams unwavering
                        dedication to client needs has been central to our
                        journey. With a commitment to excellent communication,
                        reliability, and availability, we've built lasting
                        relationships. Our core values drive us to provide
                        tailored solutions and unmatched service, ensuring
                        client success remains our top priority.
                    </p>
                </div>
                {/* <div className="d-lg-none">
                    <h5
                        onClick={toggleMenu}
                        className="my-3 fs--20"
                        id="ourHistory"
                    >
                        Our History
                        <i
                            className={`fas fa-chevron-${
                                isOpen ? "up" : "down"
                            }  fs--18 ms-4`}
                        ></i>
                    </h5>

                    <Collapse isOpen={isOpen}>
                        <p className="fs--20" style={{ lineHeight: 1.6 }}>
                            Established in 2009, ANZ Billing has rapidly grown
                            into a trusted name in medical billing. Our teams
                            unwavering dedication to client needs has been
                            central to our journey. With a commitment to
                            excellent communication, reliability, and
                            availability, we've built lasting relationships. Our
                            core values drive us to provide tailored solutions
                            and unmatched service, ensuring client success
                            remains our top priority.
                        </p>
                    </Collapse>
                </div> */}
            </Container>
        </Container>
    );
};

export default OurHistory;
