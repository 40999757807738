import React from "react";
import { Container, Row } from "reactstrap";
import checkimg from "../../assets/img/check.png";

const WhyMedicalSection = () => {
    const points = [
        "90% of your claims are processed on the first submission",
        "Boost your collection rate to 97-98%",
        "Collect payment within 35-45 days",
        "Receive 99% Net Collection",
    ];
    return (
        <Container
            fluid
            className="d-flex text-white why-medical why-medical-img p-0 "
        >
            <div
                className="h-100  w-lg-25"
                style={{
                    backdropFilter:
                        "grayscale(1) contrast(1) brightness(1.2) blur(2px)",
                }}
            ></div>
            <Container fluid className="rounded w-100 p-0 justify-content-center position-relative">
                <div
                    className="bg-site-primary position-absolute  h-100 w-100"
                    style={{ opacity: 0.9 }}
                ></div>
                <Row
                    className="pt-5 mt-lg-4 position-relative w-100"
                    style={{ zIndex: 1 }}
                >
                    <div className="d-flex justify-content-center  align-items-center">
                        <h2 className="text-white fs--22 fs-md--32 fw--600">
                            Why medical billing with ANZ?
                        </h2>
                    </div>
                </Row>
                <Row
                    className="pb-5 px-4  position-relative w-100"
                    style={{ zIndex: 1 }}
                >
                    {points.map((point, index) => {
                        return (
                            <div
                                key={`WhyMedical-${index}`}
                                md={4}
                                className=" mt-4 d-flex juatify-content-center align-items-center gap-3"
                            >
                                {/* <Check /> */}
                                <div>
                                    <img
                                        width={30}
                                        src={checkimg}
                                        alt=""
                                        className=""
                                    ></img>
                                </div>
                                <h4 className="my-3 fs--18 fs-md--22 text-white" title={point}>
                                    {point}
                                </h4>
                            </div>
                        );
                    })}
                </Row>
            </Container>
            <div
                className="h-100 w-lg-50"
                style={{ backgroundColor: "#e4eef8", opacity: 0.9 }}
            ></div>
        </Container>

        // <Container className=" bg-site-primary rounded">
        //     <Row className="pt-5">
        //         <div className="d-flex justify-content-center  align-items-center">
        //             <h3 className="text-white fs--32 fw--600">
        //                 Why medical billing with ANZ?
        //             </h3>
        //         </div>
        //     </Row>
        //     <Row className="pb-5 px-4 ">
        //         {points.map((point, index) => {
        //             return (
        //                 <div
        //                     key={`WhyMedical-${index}`}
        //                     md={4}
        //                     className=" mt-4 d-flex juatify-content-center align-items-center gap-3"
        //                 >
        //                     {/* <Check /> */}
        //                     <div>
        //                         <img
        //                             width={40}
        //                             src={checkimg}
        //                             alt=""
        //                             className=""
        //                         ></img>
        //                     </div>
        //                     <h5 className="my-3 text-white" title={point}>
        //                         {point}
        //                     </h5>
        //                 </div>
        //             );
        //         })}
        //     </Row>
        // </Container>
    );
};

export default WhyMedicalSection;
