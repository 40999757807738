import React from "react";
import { Container, Row, Col } from "reactstrap";
import MissionImg from "../../assets/img/mission.png";

const OurMissionHero = () => {
    return (
        <Container className="bg-site-primary text-white py-5" fluid>
            <Container className="py-5 my-5">
                <Row className="justify-content-between align-items-center">
                    <Col lg="7">
                        {" "}
                        <div
                            className="d-flex flex-column our-mission"
                            style={{ zIndex: 1 }}
                        >
                            <h1 className="fs--40 mb-3 lh-3rem text-site-lite">
                                We are innovators in medical billing
                            </h1>
                            <p
                                className="w-100 fs--18 mr-5 text-site-lite"
                                style={{ lineHeight: 1.8,  }}
                            >
                                At ANZ Billing, our mission is to revolutionize
                                medical billing by providing unparalleled
                                personalized service. We are dedicated to
                                delivering the utmost care and attention to each
                                of our clients, ensuring their unique needs are
                                met with precision and efficiency. With a
                                commitment to trust and communication at our
                                core, we strive to build lasting relationships
                                based on integrity and reliability. Our tailored
                                solutions are guaranteed to boost revenue and
                                claims collections, driven by our values of
                                accuracy, transparency, and security. By
                                prioritizing our clients' success, we aim to
                                redefine the standard of excellence in medical
                                billing, one personalized solution at a time.
                            </p>
                        </div>
                    </Col>
                    <Col lg="5">
                        {" "}
                        <div className="d-none d-lg-flex w-100 ">
                            <img
                                alt=""
                                src={MissionImg}
                                className="image-fluid w-100"
                            ></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default OurMissionHero;
